import { t } from '@lingui/macro'
import { useMemo } from 'react'
import { createApiDataHook, createApiItemHook } from '../../scripts/api'
import { TrackedAxiosRequest, useTrackedAxiosRequest } from '../../scripts/useTrackedPromise'
import { prefixApiParams } from '../../scripts/utils'
import { FileData } from '../../types/FileDataTypes'
import { useRefreshSWR } from '../local/hooks/swrHooks'
import { GroupApprovalStatus } from './Status'
import { usePermission } from './User'

export type CertificateCodeDataMinimal = {
  id: number
  code: string
  internalCode?: string
  status: GroupApprovalStatus
  order: string
  product: string
  protocolUrl: string
}

export type CertificateCodeData = CertificateCodeDataMinimal & {
  certificates: {
    id: number
    file: FileData
  }[]
  protocolUrl: string
}

export const useCertificateCode = createApiItemHook<CertificateCodeData>('/certificatecodes')

export type UseCertificateCodesParams = {
  search?: string | null
  filter?: string | null
  orderId?: string | number
  productId?: string | number
}

export const useCertificateCodes = createApiDataHook<CertificateCodeDataMinimal[], UseCertificateCodesParams>(
  ({ orderId, productId, ...params }) => [
    orderId && productId
      ? `/orders/${orderId}/products/${productId}/certificatecodes`
      : productId
      ? `/products/${productId}/certificatecodes`
      : '/certificatecodes',
    params
  ]
)

export type CreateCertificateCodeInput = {
  readonly orderId: number
  readonly productId: number
  readonly code: string
  readonly certificates?: number[]
}
export const useCanCreateCertificateCodes = (): boolean => usePermission('Zeugniscodes_anlegen')
export const useCreateCertificateCode = (): {
  run: (data: CreateCertificateCodeInput, onSuccess?: (data: CertificateCodeDataMinimal) => unknown) => void
  running: boolean
} => {
  const refresh = useRefreshSWR()
  return useTrackedAxiosRequest<
    [data: CreateCertificateCodeInput, onSuccess?: (data: CertificateCodeDataMinimal) => unknown],
    CertificateCodeDataMinimal
  >(
    () => ({
      createRequestData: ({ orderId, productId, ...data }) => {
        return [
          `/orders/${orderId}/products/${productId}/certificatecodes`,
          prefixApiParams<Omit<CreateCertificateCodeInput, 'orderId' | 'productId'>>(data),
          { method: 'POST' }
        ]
      },
      thenFn: (response, inputData, onSuccess) => {
        refresh(({ path }) => {
          return path.includes('/certificatecodes')
        }).then(() => {
          onSuccess?.(response.data.data)
        })
      },
      messages: {
        success: {
          title: t`Speichern erfolgreich`
        },
        error: {
          title: t`Speichern fehlgeschlagen`
        }
      }
    }),
    [refresh]
  )
}

export const useCanApproveCertificateCodes = (): boolean => usePermission('Zeugnisse_pruefen')
export const useSetCertificateCodeApproval = (
  certificateCodeId: number | undefined
): TrackedAxiosRequest<[{ approval: 'open' | 'closed' }]> & {
  open: () => void
  close: () => void
} => {
  const actionAllowed = useCanApproveCertificateCodes()
  const refresh = useRefreshSWR()
  const { run, running, result, error, cancel } = useTrackedAxiosRequest<[{ approval: 'open' | 'closed' }], unknown>(
    () => ({
      createRequestData(data) {
        return [
          `/certificatecodes/${certificateCodeId}`,
          prefixApiParams<{ approval: string }>(data),
          { method: 'PATCH' }
        ]
      },
      thenFn: () => {
        refresh(({ path }) => {
          return path.includes('/certificatecodes')
        })
      },
      messages: {
        success: {
          title: t`Erfolgreich aktualisiert`
        },
        error: {
          title: t`Der Zertifikatscode konnte nicht angepasst werden.`
        }
      },
      actionAllowed
    }),
    [actionAllowed, certificateCodeId, refresh]
  )

  return useMemo(
    () => ({
      run,
      running,
      open: () => run({ approval: 'open' }),
      close: () => run({ approval: 'closed' }),
      actionAllowed,
      result,
      error,
      cancel
    }),
    [actionAllowed, cancel, error, result, run, running]
  )
}

export const useDeleteCertificateCode = () => {
  const refresh = useRefreshSWR()

  return useTrackedAxiosRequest<[data: { id: number }, onSuccess?: () => unknown], void>(
    () => ({
      createRequestData: ({ id }) => {
        return [`/certificatecodes/${id}`, {}, { method: 'DELETE' }]
      },
      thenFn: (response, inputData, onSuccess) => {
        refresh(({ path }) => {
          return path.includes('/certificatecodes')
        }).then(() => {
          onSuccess?.()
        })
      },
      messages: {
        success: {
          title: t`Zertifikatscode erfolgreich gelöscht`
        },
        error: {
          title: t`Zertifikatscode konnte nicht gelöscht werden`
        }
      }
    }),
    [refresh]
  )
}
