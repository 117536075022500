import React, { useCallback, useMemo } from 'react'
import { Header, HeaderToolbarButton } from '../../../components/ListView/Header'
import {
  useCanApproveCertificates,
  useCertificates,
  useUpdateCertificateApproval
} from '../../../data/remote/Certificate'
import { OrderDataMinimal } from '../../../data/remote/Order'
import mailSvg from 'jsx:./../../../assets/icons/envelope.svg'
import checkSvg from 'jsx:./../../../assets/icons/check.svg'

import { t } from '@lingui/macro'
import {
  CertificateCodeDataMinimal,
  useCertificateCode,
  useDeleteCertificateCode
} from '../../../data/remote/CertificateCode'
import { ProductDataMinimal } from '../../../data/remote/Product'
import { useConfirm } from '../../../navigation/Notifications'
import { useSettings } from '../../../data/remote/Settings'
import { usePermission } from '../../../data/remote/User'
import DotsMenu from '../../../components/DotsMenu'
import { DropdownItem } from '../../../components/Overlays/Dropdown'

export type FilesHeaderProps = {
  order: OrderDataMinimal | undefined
  product: ProductDataMinimal | undefined
  certificateCode: CertificateCodeDataMinimal | undefined
  children?: React.ReactNode
}

const useEmailLink = (order: OrderDataMinimal | undefined, product: ProductDataMinimal | undefined) => {
  const { settings } = useSettings()
  return useCallback(() => {
    if (!order || !settings) return
    const articleText = product ? t`Artikel: ${product.sku}` : ''
    const subject = t`Bestellnummer: ${order.identifier}${articleText ? ', ' + articleText : ''}`
    const body = t`Sehr geehrte Damen und Herren,`
    const link = `mailto:${encodeURIComponent(order.supplierEmail)}?subject=${encodeURIComponent(
      subject
    )}&bcc=${encodeURIComponent(settings.certificateReminder.bcc)}&body=${encodeURIComponent(body)}`
    location.href = link
  }, [order, product, settings])
}

const useDeleteCertificateCodeConfirmation = (certificateCode: CertificateCodeDataMinimal | undefined) => {
  const deleteCertificateCode = useDeleteCertificateCode()
  const confirm = useConfirm()

  return useCallback(() => {
    if (!certificateCode?.id) return
    confirm({
      title: t`Zeugniscode löschen`,
      text: t`Soll der Zeugniscode wirklich gelöscht werden?`,
      options: [
        { label: t`Abbrechen`, type: 'secondary' },
        { label: t`Löschen`, type: 'danger', onClick: () => deleteCertificateCode.run({ id: certificateCode.id }) }
      ]
    })
  }, [certificateCode, confirm, deleteCertificateCode])
}

const FilesHeader: React.FC<FilesHeaderProps> = ({ children, order, product, certificateCode }) => {
  const canApprove = useCanApproveCertificates()
  const canDeleteOwnCertificateCodes = usePermission('Eigene_Zeugnisse_verwalten')
  const sendEmail = useEmailLink(order, product)
  const deleteCertificateCodeConfirmation = useDeleteCertificateCodeConfirmation(certificateCode)
  const { data: certicateCodeData } = useCertificateCode(certificateCode?.id)
  const { data: certificates } = useCertificates({ codeId: certificateCode?.id }, !!certificateCode)

  const pendingCertificates = useMemo(
    () => certificates?.filter((x) => x.status.approval === 'pending').map((x) => x.id) ?? [],
    [certificates]
  )
  const updateCertificateApproval = useUpdateCertificateApproval(pendingCertificates)
  const confirm = useConfirm()

  const acceptAllCertificates = useCallback(() => {
    confirm({
      title: t`Alle offenen Zeugnisse freigeben`,
      text: t`Sollen alle offenen Zeugnisse freigegeben werden?`,
      options: [
        { label: t`Abbrechen`, type: 'secondary' },
        { label: t`Freigeben`, type: 'primary', onClick: updateCertificateApproval.approve }
      ]
    })
  }, [confirm, updateCertificateApproval.approve])

  const renderToolbarButtons = () => {
    if (canApprove) {
      return (
        <>
          {order && <HeaderToolbarButton icon={mailSvg} title={t`E-Mail an den Lieferanten`} onClick={sendEmail} />}
          {certificateCode && (
            <HeaderToolbarButton
              icon={checkSvg}
              title={t`Alle offenen Zeugnisse freigeben`}
              disabled={!pendingCertificates.length}
              onClick={acceptAllCertificates}
            />
          )}
          {(certicateCodeData?.protocolUrl || (certificateCode?.status.approval !== 'closed' && certificateCode)) && (
            <DotsMenu>
              <>
                {certicateCodeData?.protocolUrl && (
                  <DropdownItem href={certicateCodeData.protocolUrl} target="_blank">
                    {t`WEK-Protokoll öffnen`}
                  </DropdownItem>
                )}
                {certificateCode?.status.approval !== 'closed' &&
                  certificateCode &&
                  !certicateCodeData?.status.locked && (
                    <DropdownItem
                      onClick={deleteCertificateCodeConfirmation}
                      textColor="danger"
                    >{t`Zeugniscode löschen`}</DropdownItem>
                  )}
              </>
            </DotsMenu>
          )}
        </>
      )
    } else if (!!certificateCode && canDeleteOwnCertificateCodes && certificateCode?.status.approval !== 'closed') {
      return (
        <DotsMenu>
          <DropdownItem
            onClick={deleteCertificateCodeConfirmation}
            textColor="danger"
          >{t`Zeugniscode löschen`}</DropdownItem>
        </DotsMenu>
      )
    }
  }

  return (
    <div className="d-flex flex-column flex-grow-1">
      <Header title={certificateCode ? t`Zeugnisse` : undefined} toolbar={renderToolbarButtons()} />
      {children}
    </div>
  )
}

export default FilesHeader
