import { useCallback } from 'react'
import { Permission, usePermissions } from '../../../data/remote/User'
import { createRoute } from '../../../navigation/createRoute'
import CertificateCodeColumn from './Columns/CertificateCodeColumn'
import DataColumn from '../../../components/ListView/DataColumn'
import { t } from '@lingui/macro'
import { useOrderData } from './Columns/useOrderData'
import { useProductsData } from './Columns/useProductsData'
import { useCertificateCodesData } from './Columns/useCertificateCodesData'
import CertificateFilesTable from './Columns/CertificateFilesTable'
import FilesHeader from './FilesHeader'

export const Certificates = createRoute(
  'Certificates',
  function Certificates({ params: { orderId, productId, certificateCode }, generatePath, setParams }) {
    const { user, actionAllowed } = usePermissions()

    const supplierId = actionAllowed(Permission.Alle_Zeugnisse_verwalten) ? undefined : user?.id

    const orderData = useOrderData({
      setParams,
      generatePath,
      supplierId,
      orderId
    })

    const productsData = useProductsData({
      setParams,
      generatePath,
      supplierId,
      orderData,
      productId
    })

    const certificateCodesData = useCertificateCodesData({
      setParams,
      generatePath,
      supplierId,
      orderData,
      productsData,
      certificateCode
    })

    const generateCertificateCodePath = useCallback(
      (id: string) => generatePath({ orderId, productId, certificateCode: id }),
      [generatePath, orderId, productId]
    )

    const orderDataLength = orderData.items?.length || ''
    const orderDataCount = orderDataLength ? '(' + orderDataLength + ')' : ''

    const productsDataLength = productsData.items?.length || ''
    const productsDataCount = productsDataLength ? '(' + productsDataLength + ')' : ''

    const certificateCodesDataLength = certificateCodesData.items?.length || ''
    const certificateCodesDataCount = certificateCodesDataLength ? '(' + certificateCodesDataLength + ')' : ''

    return (
      <>
        <DataColumn
          title={t`Bestellungen` + ' ' + orderDataCount}
          {...orderData}
          arrow={orderId ? 'right' : undefined}
        />
        {orderData.current && (
          <DataColumn
            title={t`Artikel` + ' ' + productsDataCount}
            {...productsData}
            arrow={productId ? 'right' : undefined}
          />
        )}
        {orderData.current && productsData.current && (
          <CertificateCodeColumn
            {...certificateCodesData}
            title={t`Zeugniscode / Schmelzennr.` + ' ' + certificateCodesDataCount}
            generatePath={generateCertificateCodePath}
            order={orderData.current}
            product={productsData.current}
            arrow={certificateCode ? 'right' : undefined}
          />
        )}
        <FilesHeader
          order={orderData.current}
          product={productsData.current}
          certificateCode={certificateCodesData.current}
        >
          {!!certificateCodesData.current && <CertificateFilesTable certificateCode={certificateCodesData.current} />}
        </FilesHeader>
      </>
    )
  }
)
