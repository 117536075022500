import { t } from '@lingui/macro'
import { SearchColumnProps } from '.'
import { ColumnData, useColumnData } from '../../../../components/ListView/DataColumn'
import { OrderDataMinimal, useOrders } from '../../../../data/remote/Order'
import defaultColumnData from './defaultColumnData'
import { getParentSelection } from './utils'

export const useOrderData = ({
  setSelection,
  selection,
  entrypoint,
  setEntrypoint,
  setColumnSelection
}: SearchColumnProps): ColumnData<OrderDataMinimal> =>
  useColumnData<OrderDataMinimal>(
    ({ setFilter }) => {
      return {
        useItems(search, filter) {
          const { suppliers: supplierId, products: productId } = getParentSelection(entrypoint, selection, 'orders')
          return useOrders(
            {
              search,
              filter,
              supplierId,
              productId
            },
            !!supplierId || !!productId || entrypoint === 'orders'
          )
        },
        filterOptions: {
          ['']: t`Alle Bestellungen`,
          pending: t`Mit ungeprüften Zeugnissen`,
          denied: t`Mit abgelehnten Zeugnissen`,
          open: t`Mit offenem Status`
        },
        renderItem: (order) => ({
          id: order.id,
          title: order.identifier,
          onClick: () => {
            setColumnSelection(order.id, false)
          },
          onDoubleClick: () => {
            setColumnSelection(order.id, true, setFilter, order.identifier)
          },
          gray: order.status.approval === 'closed',
          approved: order.status.approval === 'approved' || order.status.approval === 'closed',
          denied: order.status.approval === 'denied',
          pending: order.status.pending,
          mandatory: order.status.mandatory
        }),
        ...defaultColumnData('orders', {
          entrypoint,
          setEntrypoint,
          selection,
          setSelection
        })
      }
    },
    [entrypoint, selection, setColumnSelection, setEntrypoint, setSelection]
  )
