import React, { ReactNode } from 'react'
import { Variant } from 'react-bootstrap/esm/types'
import { Scrollview } from '../Layout/Scrollview'
import { Header } from './Header'

export type FlexContainerProps = {
  header?: React.ReactElement
  padding?: boolean
  bg?: Variant
  children?: ReactNode[] | ReactNode
}

export const FlexContainer: React.FC<FlexContainerProps> = ({ header, padding, bg, children }) => {
  return (
    <div className="flex-container">
      <Header>{header}</Header>
      <Scrollview padding={padding}>{children}</Scrollview>
    </div>
  )
}
