import React, { useMemo, useRef } from 'react'
import { FlexContainer } from '../../../components/ListView/FlexContainer'
import { Loader } from '../../../components/Loader'
import { usePage } from '../../../data/remote/Page'
import ContentElements from '../../../components/ContentElements/ContentElements'

export const PageContent: React.FC = () => {
  const contentRef = useRef(null)
  const { loading, page, content } = usePage()

  const loaderWidth = useMemo(() => {
    // TODO: Hier die Breite des Containers in die Berechnung aufnehmen, damit die Skeleton-Loader eine zufällige Breite erhalten
    //return contentRef.current ? contentRef.current.offsetWidth / 1.5 : undefined
    return 1000
  }, [])

  const isHelp = true

  return (
    <>
      <FlexContainer bg={isHelp ? 'light' : 'white'} padding>
        <div className="text-content" ref={contentRef}>
          <h1 className="h2">{loading ? <Loader width={400} dynamicWidth={0.7} /> : page?.title}</h1>
          <div className={isHelp ? 'container-box' : ''}>
            {loading ? (
              <>
                {[...Array(6)].map((e, i) => (
                  <Loader key={i} width={loaderWidth} dynamicWidth={0.7} />
                ))}
                <h3 className="mt-5">
                  <Loader width={400} dynamicWidth={0.7} />
                </h3>
                {[...Array(6)].map((e, i) => (
                  <Loader key={i} width={loaderWidth} dynamicWidth={0.7} />
                ))}
              </>
            ) : (
              <ContentElements colPos={0} contents={content?.colPos0} />
            )}
          </div>
        </div>
      </FlexContainer>
    </>
  )
}
