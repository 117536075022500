import classNames from 'classnames'
import React from 'react'
import { Variant } from 'react-bootstrap/esm/types'
import { Icon } from './Icon'
import { Tooltip } from './Overlays/Tooltip'

export type StatusIconProps = {
  icon?: React.ComponentType<React.SVGProps<SVGSVGElement>>
  title?: string
  variant?: Variant
  size?: 'sm' | 'lg'
  className?: string
  disabled?: boolean
}

export const StatusIcon: React.FC<StatusIconProps> = ({
  icon,
  title,
  variant = 'primary',
  size,
  className,
  disabled
}) => {
  return (
    <div style={{ pointerEvents: 'all' }}>
      <Tooltip text={title}>
        <div
          className={classNames(
            'status-icon',
            variant && `bg-${variant}`,
            size && `status-${size}`,
            disabled && 'status-disabled',
            className
          )}
        >
          {icon && <Icon svg={icon} />}
        </div>
      </Tooltip>
    </div>
  )
}
