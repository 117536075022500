import { Certificates, CertificatesBySupplier } from './LoggedIn/Certificates'
import { Complaints } from './LoggedIn/Complaints/Complaints'
import { EditProfile } from './LoggedIn/EditProfile'
import FileCheck from './LoggedIn/Files/FileCheck'
import FileManagement from './LoggedIn/Files/FileManagement'
import Files from './LoggedIn/Files/Files'
import { Help } from './LoggedIn/Help'
import { Information } from './LoggedIn/Information'
import { Logout } from './LoggedIn/Logout'
import { PageContent } from './LoggedIn/PageContent'
import { Search } from './LoggedIn/Search'
import { SteelworkManagement } from './LoggedIn/Steelworks/SteelworkManagement'
import { SteelworkProtocols } from './LoggedIn/Steelworks/SteelworkProtocols'
import { SupplierRating } from './LoggedIn/SupplierRating'
import { Suppliers } from './LoggedIn/Suppliers'
import { Activate } from './Login/Activate'
import { ForgotPassword } from './Login/ForgotPassword'
import { Login } from './Login/Login'
import ConfirmEmail from './Registration/ConfirmEmail'
import { BasicRouteInfo, RouteName } from './routeParams'

const routeComponents: {
  [key in RouteName]: React.ComponentType<BasicRouteInfo>
} = {
  // Login
  Login: Login,
  ForgotPassword: ForgotPassword,
  Activate: Activate,
  ConfirmEmail: ConfirmEmail,
  // Portal
  PageContent: PageContent,
  Certificates: Certificates,
  Complaints: Complaints,
  SteelworkManagement: SteelworkManagement,
  SteelworkProtocols: SteelworkProtocols,
  CertificatesBySupplier: CertificatesBySupplier,
  Search: Search,
  FileManagement: FileManagement,
  FileCheck: FileCheck,
  Files: Files,
  Suppliers: Suppliers,
  SupplierRating: SupplierRating,
  EditProfile: EditProfile,
  Help: Help,
  Information: Information,
  Logout: Logout
}

/**
 * These Routes are allowed to be loaded, even if no one is logged in
 */
export const unauthorizedRouteNames: Set<RouteName> = new Set(['Login', 'ForgotPassword', 'Activate', 'ConfirmEmail'])

export default routeComponents
