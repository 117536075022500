import { t } from '@lingui/macro'
import { useCallback } from 'react'

import DotsMenu from '../../../components/DotsMenu'
import { CommentTarget } from '../../../data/remote/Comment'

import {
  InternalFileDataMinimal,
  useDeleteInternalFile,
  useUpdateInternalFileVisibility
} from '../../../data/remote/InternalFile'
import { useConfirm } from '../../../navigation/Notifications'
import { DropdownItem } from '../../../components/Overlays/Dropdown'

type InternalFilesMenuProps = {
  file: InternalFileDataMinimal
  addCommentClick: () => void
  toggleVisibility: () => void
  deleteFile: () => void
  canComment: boolean
  canManage: boolean
  isClosed: boolean
}

const InternalFilesMenuStructure = ({
  file,
  addCommentClick,
  toggleVisibility,
  deleteFile,
  canComment,
  canManage,
  isClosed
}: InternalFilesMenuProps) => {
  return (
    <DotsMenu>
      {canComment && <DropdownItem key="comment" onClick={addCommentClick}>{t`Kommentar hinzufügen`}</DropdownItem>}
      <DropdownItem href={file.file.publicUrl} target="_blank" key="download">{t`Öffnen`}</DropdownItem>
      {canManage && (
        <DropdownItem key="visibility" onClick={toggleVisibility}>
          {file.public ? t`Datei unsichtbar schalten` : t`Datei auf "öffentlich" stellen`}
        </DropdownItem>
      )}

      {!isClosed && canManage && (
        <DropdownItem key="delete" onClick={deleteFile} textColor="danger">
          Löschen
        </DropdownItem>
      )}
    </DotsMenu>
  )
}

const InternalFileMenu: React.FC<{
  file: InternalFileDataMinimal
  canManage: boolean
  canComment?: boolean
  addComment?: (target: CommentTarget) => void
  isClosed?: boolean
}> = ({ file, canManage, canComment, addComment, isClosed }) => {
  const addCommentClick = useCallback(() => {
    addComment?.({
      type: 'internalfiles',
      id: file.id
    })
  }, [addComment, file.id])

  const updateInternalFileVisibility = useUpdateInternalFileVisibility()
  const toggleVisibility = useCallback(() => {
    updateInternalFileVisibility.run({
      id: file.id,
      public: !file.public
    })
  }, [file.id, file.public, updateInternalFileVisibility])

  const deleteInternalFile = useDeleteInternalFile()

  const confirm = useConfirm()

  const handleDeleteInternalFile = () => {
    confirm({
      title: t`Interne Datei löschen`,
      text: t`Soll die Datei wirklich gelöscht werden?`,
      options: [
        { label: t`Abbrechen`, type: 'secondary' },
        {
          label: t`Datei löschen`,
          type: 'danger',
          onClick: () => deleteInternalFile.run({ id: file.id })
        }
      ]
    })
  }

  return (
    <InternalFilesMenuStructure
      file={file}
      addCommentClick={addCommentClick}
      toggleVisibility={toggleVisibility}
      deleteFile={handleDeleteInternalFile}
      canComment={canComment ? canComment : false}
      canManage={canManage}
      isClosed={isClosed ? isClosed : false}
    />
  )
}

export default InternalFileMenu
