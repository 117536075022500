import './polyfills'

import * as ReactDOMClient from 'react-dom/client'
import App from './App'
import ErrorBoundary from './views/ErrorBoundary'

const root = ReactDOMClient.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <ErrorBoundary>
    <App />
  </ErrorBoundary>
)
