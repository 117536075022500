import React, { SVGProps, useMemo } from 'react'
import { Tooltip } from '../Overlays/Tooltip'
import { Status, TableStatusProps } from '../../types/StatusTypes'
import MenuClose from 'jsx:./../../assets/icons/menu-close.svg'
import Pencil from 'jsx:./../../assets/icons/pencil.svg'
import Check from 'jsx:./../../assets/icons/check.svg'
import Clock from 'jsx:./../../assets/icons/clock.svg'

const data: {
  [key in Status | string]: {
    readonly className: string
    readonly icon: React.ComponentType<SVGProps<SVGSVGElement>>
    readonly title: string
  }
} = {
  [Status.draft]: {
    className: 'bg-dark text-white',
    icon: Pencil,
    title: 'Entwurf'
  },
  [Status.approved]: {
    className: 'bg-success text-white',
    icon: Check,
    title: 'Genehmigt'
  },
  [Status.inProgress]: {
    className: 'bg-warning text-white',
    icon: Clock,
    title: 'In Bearbeitung'
  },
  [Status.open]: {
    className: 'bg-danger text-white',
    icon: MenuClose,
    title: 'Offen'
  }
}

const TableStatus = React.forwardRef<HTMLDivElement, TableStatusProps>(function TableStatusIcon(
  { status = 'pending', size = 22, tooltip, ...props },
  ref
) {
  const style = useMemo<React.CSSProperties>(
    () => ({
      ...props.style,
      width: size + 'px',
      height: size + 'px',
      borderRadius: size / 10 + 'px',
      display: 'inline-flex',
      alignItems: 'center',
      justifyContent: 'center'
    }),
    [props.style, size]
  )

  const { className, icon: Icon, title } = data[status] ?? {}

  const svgStyle = useMemo<React.CSSProperties>(
    () => ({
      width: size * 0.6 + 'px',
      height: size * 0.6 + 'px'
    }),
    [size]
  )

  return (
    <Tooltip text={tooltip || title}>
      <div
        ref={ref}
        {...props}
        className={['approval-icon', className, props.className].filter((x) => !!x).join(' ')}
        style={style}
      >
        {Icon && <Icon style={svgStyle} />}
      </div>
    </Tooltip>
  )
})

export default TableStatus
