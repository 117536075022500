import {
  useAssignedProductGroupsForSupplier,
  useAssignedRegulationFileIds,
  useDeleteSupplier,
  useSupplier
} from '../../../data/remote/Supplier'
import { Loading } from '../Loading'
import { ContainerBox } from '../../../components/Layout/ContainerBox'
import { EditProfileForm } from '../../../components/Profile/EditProfileForm'
import Toolbar from '../../../components/Layout/Toolbar'
import { FlexContainer } from '../../../components/ListView/FlexContainer'
import { useConfirm } from '../../../navigation/Notifications'
import { useCallback, useMemo } from 'react'
import { DropdownItem } from '../../../components/Overlays/Dropdown'
import DotsMenu from '../../../components/DotsMenu'
import { t } from '@lingui/macro'
import ToggleButtons from '../../../components/ToggleButtons'
import { useProductGroups } from '../../../data/remote/ProductGroup'
import SelectFilesInput from '../Files/SelectFilesInput'
import SupplierSpecialFiles from './SupplierSpecialFiles'
import SupplierComplaints from './SupplierComplaints'
import SupplierRatingTable from './SupplierRatingTable'
import { Permission, usePermissions } from '../../../data/remote/User'

const SupplierSingle: React.FC<{
  supplierId: number
  baseUrl: string
}> = function SupplierSingle({ supplierId, baseUrl }) {
  const { actionAllowed } = usePermissions()
  const { data: supplier, loading: supplierLoading } = useSupplier(supplierId)
  const deleteSupplier = useDeleteSupplier()

  const confirm = useConfirm()
  const deleteUser = useCallback(() => {
    if (supplier) {
      confirm({
        title: t`Lieferanten löschen?`,
        text: <>{t`Soll der Lieferant „${supplier.company}“ wirklich gelöscht werden?`}</>,
        options: [
          { label: t`Abbrechen` },
          {
            type: 'danger',
            label: t`Löschen`,
            onClick: () => {
              deleteSupplier.run(supplier.id, baseUrl)
            }
          }
        ]
      })
    }
  }, [baseUrl, confirm, deleteSupplier, supplier])

  const productGroups = useProductGroups()
  const productGroupOptions = useMemo(
    () => productGroups.data?.map(({ id, localizedTitle }) => ({ id: id.toString(), label: localizedTitle })) ?? [],
    [productGroups.data]
  )

  const { regulationFileIds, run: setRegulationFileIds } = useAssignedRegulationFileIds(supplierId)
  const { productGroupIds, run: setProductGroup } = useAssignedProductGroupsForSupplier(supplierId)

  const productGroupSelection = useMemo(() => {
    return Object.fromEntries(productGroupIds.map((id) => [id.toString(), true]))
  }, [productGroupIds])

  const handleProductGroupChange = useCallback(
    (id: string, checked: boolean) => {
      const updatedIds = checked
        ? [...productGroupIds, parseInt(id)]
        : productGroupIds.filter((groupId) => groupId !== parseInt(id))
      setProductGroup(updatedIds)

      const groupFileIds = productGroups.data?.find((x) => x.id.toString() === id)?.regulationFiles ?? []
      const nextFileIds = regulationFileIds
        .filter((x) => !groupFileIds?.includes(x))
        .concat(checked ? groupFileIds : [])
        //filter all 0 values
        .filter((x) => x)
      setRegulationFileIds(nextFileIds)
    },
    [productGroupIds, productGroups.data, regulationFileIds, setProductGroup, setRegulationFileIds]
  )

  if (!supplierLoading && !supplier) {
    return <></>
  }

  return supplierLoading ? (
    <Loading />
  ) : (
    <>
      <Toolbar
        right={
          <div className="pr-1">
            <DotsMenu>
              <DropdownItem textColor="danger" onClick={deleteUser}>{t`Löschen`}</DropdownItem>
            </DotsMenu>
          </div>
        }
      />
      <FlexContainer padding>
        <h1 className="h2">{supplier!.company}</h1>

        <ContainerBox>
          <EditProfileForm profileId={supplier!.id} />
        </ContainerBox>

        {actionAllowed(Permission.Alle_Lieferantenbewertungen_ansehen) && (
          <SupplierRatingTable supplierData={supplier!} />
        )}

        {actionAllowed(Permission.Alle_Reklamationen_verwalten) && <SupplierComplaints supplierId={supplier!.id} />}

        <ContainerBox>
          <h2>{t`Produktgruppen`}</h2>
          <ToggleButtons
            options={productGroupOptions}
            value={productGroupSelection}
            onChange={handleProductGroupChange}
          />
        </ContainerBox>
        <ContainerBox>
          <h2>{t`Dateizuordnung Lieferant`}</h2>
          <div className="container-box-content-p0 border-top">
            <SelectFilesInput value={regulationFileIds} onChange={setRegulationFileIds} supplier={supplier} />
          </div>
        </ContainerBox>

        <ContainerBox>
          <h2>{t`Dateien für ${supplier?.company}`}</h2>
          <div className="container-box-content-p0 border-top">
            {supplier?.id && <SupplierSpecialFiles supplierId={supplier.id} />}
          </div>
        </ContainerBox>
      </FlexContainer>
    </>
  )
}

export default SupplierSingle
