import { t } from '@lingui/macro'
import { createApiDataHook, createApiItemHook } from '../../scripts/api'
import { useTrackedAxiosRequest } from '../../scripts/useTrackedPromise'
import { prefixApiParams } from '../../scripts/utils'
import { FileData } from '../../types/FileDataTypes'
import { useRefreshSWR } from '../local/hooks/swrHooks'

export type InternalFileDataMinimal = {
  id: number
  file: FileData
  tstamp: string
  public: boolean
  /** Number of comments attached to this file */
  comments: number
}
export type InternalFileData = InternalFileDataMinimal

export const useInternalFile = createApiItemHook<InternalFileData>('/internalfiles')

export type UseInternalFilesParams = {
  search?: string | null
  codeId?: number
}

export const useInternalFiles = createApiDataHook<InternalFileDataMinimal[], UseInternalFilesParams>(
  ({ codeId, ...params }) => [codeId ? `/certificatecodes/${codeId}/internalfiles` : '/internalfiles', params]
)

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useUpdateInternalFileVisibility = () => {
  const refresh = useRefreshSWR()
  return useTrackedAxiosRequest<
    [
      {
        id: number
        public: boolean
      }
    ],
    unknown
  >(
    () => ({
      createRequestData: ({ id, ...args }) => {
        return [`/internalfiles/${id}`, prefixApiParams<{ public: boolean }>(args), { method: 'PATCH' }]
      },
      thenFn: () => {
        refresh(({ path }) => path.includes('internalfiles'))
      },
      messages: {
        error: {
          title: t`Die Datei konnte nicht angepasst werden.`
        }
      }
    }),
    [refresh]
  )
}

export const useDeleteInternalFile = () => {
  const refresh = useRefreshSWR()
  return useTrackedAxiosRequest<[data: { id: number }, onSuccess?: () => unknown], void>(
    () => ({
      createRequestData: ({ id }) => {
        return [`/internalfiles/${id}`, undefined, { method: 'DELETE' }]
      },
      thenFn: (response, inputData, onSuccess) => {
        refresh(
          ({ path }) =>
            path.includes('specificfiles') ||
            path.includes('regulationfiles') ||
            path.includes('complaints') ||
            path.includes('internalfiles')
        ).then(() => {
          onSuccess?.()
        })
      },
      messages: {
        success: {
          title: t`Datei gelöscht`
        },
        error: {
          title: t`Datei konnte nicht gelöscht werden`
        }
      }
    }),
    [refresh]
  )
}
