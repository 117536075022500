/* eslint-disable import/no-unresolved */
import { Variant } from 'react-bootstrap/esm/types'
import Pencil from 'jsx:./../assets/icons/pencil.svg'
import Check from 'jsx:./../assets/icons/check.svg'
import Clock from 'jsx:./../assets/icons/clock.svg'
import MenuClose from 'jsx:./../assets/icons/menu-close.svg'
import { t } from '@lingui/macro'
import { StatusIcon, StatusIconProps } from './StatusIcon'
import React from 'react'
import { Status } from '../types/StatusTypes'

type StatusConfig = {
  icon: NonNullable<StatusIconProps['icon']>
  variant: Variant
  title: string
}

export const STATUS_CONFIG: Record<Status, StatusConfig> = {
  [Status.draft]: {
    icon: Pencil,
    variant: 'secondary',
    title: t`Entwurf`
  },
  [Status.open]: {
    icon: MenuClose,
    variant: 'danger',
    title: t`Offen`
  },
  [Status.inProgress]: {
    icon: Clock,
    variant: 'warning',
    title: t`In Bearbeitung`
  },
  [Status.approved]: {
    icon: Check,
    variant: 'success',
    title: t`Abgeschlossen`
  }
}

interface StatusDisplayProps extends Omit<StatusIconProps, 'icon' | 'variant' | 'title'> {
  status: Status
}

export const StatusDisplay: React.FC<StatusDisplayProps> = ({ status, ...props }) => {
  const config = STATUS_CONFIG[status]

  if (!config) return null

  return <StatusIcon {...props} icon={config.icon} variant={config.variant} title={config.title} />
}
