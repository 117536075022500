import React, { useMemo } from 'react'

import { Button, Col, Form, Row } from 'react-bootstrap'
import { HTMLCheckbox } from './Checkbox'

export type ToggleButtonsProps = {
  options: {
    id: string
    label: string
  }[]
  value?: Record<string, boolean | undefined>
  onChange?: (id: string, value: boolean) => void
  columns?: {
    xs?: number
    md?: number
  }
}

let lastId = 0
const nextId = () => `toggle-buttons-${++lastId}`

const ToggleButtons: React.FC<ToggleButtonsProps> = ({ options, value = {}, onChange, columns = {} }) => {
  const groupId = useMemo(nextId, [])

  return (
    <Form>
      <Row>
        {options?.map(({ id, label }) => (
          <Col key={id} xs={6} md={3} {...columns}>
            <HTMLCheckbox
              value={value[id]}
              id={`${groupId}-option-${id}`}
              className="custom-control-input"
              onChange={(checked) => onChange?.(id, checked)}
            />
            <Button
              className="w-100 mb-1 btn-checkbox-toggle"
              as="label"
              variant="outline-light text-primary"
              size="lg"
              title={label}
              htmlFor={`${groupId}-option-${id}`}
            >
              <span>{label}</span>
            </Button>
          </Col>
        ))}
      </Row>
    </Form>
  )
}

export default ToggleButtons
