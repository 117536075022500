import { t } from '@lingui/macro'
import { SupplierDataMinimal, useSuppliers } from '../../../data/remote/Supplier'
import { createRoute } from '../../../navigation/createRoute'
import SupplierSingle from './SupplierSingle'
import SupplierNew from './SupplierNew'
import DataColumn, { useColumnData } from '../../../components/ListView/DataColumn'
import { toNumber } from '../../../scripts/utils'

export const Suppliers = createRoute('Suppliers', ({ baseUrl, params: { supplierId }, generatePath }) => {
  const supplierData = useColumnData<SupplierDataMinimal>(
    () => ({
      useItems(search) {
        return useSuppliers({ search })
      },
      isActiveItem(supplier) {
        return supplierId !== 'new' && supplier.id === toNumber(supplierId)
      },
      renderItem(supplier) {
        return {
          id: supplier.id,
          title: supplier.company,
          url: generatePath({ supplierId: supplier.id })
        }
      }
    }),
    [generatePath, supplierId]
  )

  const supplierDataLength = supplierData.items?.length || ''
  const supplierDataCount = supplierDataLength ? '(' + supplierDataLength + ')' : ''

  return (
    <>
      <DataColumn
        title={t`Lieferanten` + ' ' + supplierDataCount}
        {...supplierData}
        newLabel={t`Neuen Lieferanten anlegen`}
        newUrl={generatePath({ supplierId: 'new' })}
      />
      <div className="d-flex flex-grow-1 flex-shrink-1 flex-column">
        {supplierId === 'new' && <SupplierNew baseUrl={baseUrl} />}
        {supplierId !== 'new' && !!supplierData.current && (
          <SupplierSingle supplierId={supplierData.current.id} baseUrl={baseUrl} />
        )}
      </div>
    </>
  )
})
