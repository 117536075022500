import React, { useCallback } from 'react'
import {
  OverlayTriggerProps as OverlayTriggerPropsOriginal,
  OverlayTrigger as OverlayTriggerOriginal
} from 'react-bootstrap'

export type OverlayTriggerProps = Omit<OverlayTriggerPropsOriginal, 'popperConfig' | 'children'> &
  React.PropsWithChildren<{
    disabled?: boolean
    onOpenChange?: (open: boolean) => void
  }>

const popperConfig: OverlayTriggerPropsOriginal['popperConfig'] = {
  modifiers: [
    {
      name: 'offset',
      options: {
        offset: [0, 10]
      }
    }
  ]
}

const acceptsRef = (el: Parameters<typeof React.isValidElement>[0]): el is React.ReactElement => {
  return React.isValidElement(el) && typeof el.type !== 'function'
}

const OverlayTrigger: React.FC<OverlayTriggerProps> = ({
  disabled = false,
  onOpenChange,
  onEnter,
  onExited,
  children,
  trigger = ['hover', 'focus'],
  ...props
}) => {
  return (
    <OverlayTriggerOriginal
      {...props}
      trigger={disabled ? [] : trigger}
      onEnter={useCallback(
        (node: HTMLElement) => {
          onOpenChange?.(true)
          onEnter?.(node, true)
        },
        [onEnter, onOpenChange]
      )}
      onExited={useCallback(
        (node: HTMLElement) => {
          onOpenChange?.(false)
          onExited?.(node)
        },
        [onExited, onOpenChange]
      )}
      popperConfig={popperConfig}
    >
      {(overlayProps) => {
        if (acceptsRef(children)) {
          return React.cloneElement(children, overlayProps)
        }
        return <div {...overlayProps}>{children}</div>
      }}
    </OverlayTriggerOriginal>
  )
}

export default OverlayTrigger
