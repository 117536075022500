import { useCallback } from 'react'
import { createRoute } from '../../../navigation/createRoute'
import CertificateCodeColumn from './Columns/CertificateCodeColumn'
import DataColumn from '../../../components/ListView/DataColumn'
import { t } from '@lingui/macro'
import { useOrderData } from './Columns/useOrderData'
import { useProductsData } from './Columns/useProductsData'
import { useCertificateCodesData } from './Columns/useCertificateCodesData'
import { useSupplierData } from './Columns/useSupplierData'
import CertificateFilesTable from './Columns/CertificateFilesTable'
import FilesHeader from './FilesHeader'

export const CertificatesBySupplier = createRoute(
  'CertificatesBySupplier',
  function CertificatesBySupplier({
    params: { supplierId, orderId, productId, certificateCode },
    generatePath,
    setParams
  }) {
    const supplierData = useSupplierData({
      setParams,
      generatePath,
      supplierId
    })

    const orderData = useOrderData({
      setParams,
      generatePath,
      supplierId,
      orderId,
      enabled: !!supplierData.current
    })

    const productsData = useProductsData({
      setParams,
      generatePath,
      supplierId,
      orderData,
      productId
    })

    const certificateCodesData = useCertificateCodesData({
      setParams,
      generatePath,
      supplierId,
      orderData,
      productsData,
      certificateCode
    })

    const generateCertificateCodePath = useCallback(
      (id: string) => generatePath({ supplierId, orderId, productId, certificateCode: id }),
      [generatePath, orderId, productId, supplierId]
    )

    const supplierDataLength = supplierData.items?.length || ''
    const supplierDataCount = supplierDataLength ? '(' + supplierDataLength + ')' : ''

    const orderDataLength = orderData.items?.length || ''
    const orderDataCount = orderDataLength ? '(' + orderDataLength + ')' : ''

    const productsDataLength = productsData.items?.length || ''
    const productsDataCount = productsDataLength ? '(' + productsDataLength + ')' : ''

    const certificateCodesDataLength = certificateCodesData.items?.length || ''
    const certificateCodesDataCount = certificateCodesDataLength ? '(' + certificateCodesDataLength + ')' : ''

    return (
      <>
        <DataColumn
          title={t`Lieferanten` + ' ' + supplierDataCount}
          {...supplierData}
          arrow={supplierId ? 'right' : undefined}
          type="certificate"
        />
        {supplierData.current && (
          <DataColumn
            title={t`Bestellungen` + ' ' + orderDataCount}
            {...orderData}
            arrow={orderId ? 'right' : undefined}
          />
        )}
        {orderData.current && (
          <DataColumn
            title={t`Artikel` + ' ' + productsDataCount}
            {...productsData}
            arrow={productId ? 'right' : undefined}
          />
        )}
        {orderData.current && productsData.current && (
          <CertificateCodeColumn
            {...certificateCodesData}
            generatePath={generateCertificateCodePath}
            title={t`Zeugniscode / Schmelzennr.` + ' ' + certificateCodesDataCount}
            order={orderData.current}
            product={productsData.current}
            arrow={certificateCode ? 'right' : undefined}
          />
        )}
        <FilesHeader
          order={orderData.current}
          product={productsData.current}
          certificateCode={certificateCodesData.current}
        >
          {!!certificateCodesData.current && <CertificateFilesTable certificateCode={certificateCodesData.current} />}
        </FilesHeader>
      </>
    )
  }
)
