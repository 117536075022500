import { useMemo, useCallback } from 'react'
import { useProductGroups } from '../../../data/remote/ProductGroup'
import { useAssignedProductGroupsForUser } from '../../../data/remote/Supplier'
import { UserData } from '../../../data/remote/User'
import { t } from '@lingui/macro'
import { ContainerBox } from '../../../components/Layout/ContainerBox'
import ToggleButtons from '../../../components/ToggleButtons'

type SelectProductGroupsProps = {
  user: UserData
}

export default function SelectProductGroups({ user }: SelectProductGroupsProps) {
  const productGroups = useProductGroups()
  const productGroupOptions = useMemo(
    () => productGroups.data?.map(({ id, localizedTitle }) => ({ id: id.toString(), label: localizedTitle })) ?? [],
    [productGroups.data]
  )

  const { productGroupIds, run: setProductGroup } = useAssignedProductGroupsForUser(user?.productGroups)

  const productGroupSelection = useMemo(() => {
    return Object.fromEntries(productGroupIds.map((id) => [id.toString(), true]))
  }, [productGroupIds])

  const handleProductGroupChange = useCallback(
    (id: string, checked: boolean) => {
      const updatedIds = checked
        ? [...productGroupIds, parseInt(id)]
        : productGroupIds.filter((groupId) => groupId !== parseInt(id))
      setProductGroup(updatedIds)
    },
    [productGroupIds, setProductGroup]
  )

  return (
    <ContainerBox>
      <h2>{t`Produktgruppen`}</h2>
      <ToggleButtons options={productGroupOptions} value={productGroupSelection} onChange={handleProductGroupChange} />
    </ContainerBox>
  )
}
