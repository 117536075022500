import { t } from '@lingui/macro'
import dayjs from 'dayjs'
import TableCommentsIcon from '../../../../components/DataTable/TableCommentsIcon'
import TableStatus from '../../../../components/DataTable/TableStatus'
import TableStatusIcon from '../../../../components/DataTable/TableStatusIcon'
import { ComplaintFileData } from '../../../../types/ComplaintDataTypes'

import { AddCommentModalRef } from '../../Certificates/AddCommentModal'
import ComplaintFilesMenu from './ComplaintFilesMenu'
import { FileData } from '../../../../types/FileDataTypes'
import { Tooltip } from '../../../../components/Overlays/Tooltip'
import { truncate } from 'lodash'

type generateComplaintStructureItemProps = {
  nameText: string
  addComment: React.RefObject<AddCommentModalRef>
  fileData?: ComplaintFileData
  fileAnswer?: FileData
  complaintFileType: string
  complaintId: number
  onVisibilityChange?: (complaintFile: ComplaintFileData, visibile: boolean) => void
  isSupplier: boolean
}

export type ComplaintStructureItem = {
  name: React.ReactNode
  date: string
  comments: React.ReactNode | number
  visible: React.ReactNode | number
  status?: React.ReactNode
  fileAnswer?: FileData | undefined
  menu: React.ReactNode
}

const generateComplaintStructureItem = ({
  nameText,
  addComment,
  complaintId,
  fileData,
  complaintFileType,
  onVisibilityChange,
  isSupplier
}: generateComplaintStructureItemProps): ComplaintStructureItem => ({
  name: (
    <>
      {nameText}
      {fileData?.file && (
        <div className="hasFile">
          {!isSupplier ? (
            <Tooltip
              text={
                <>
                  <strong>Hochgeladen von: </strong>
                  {fileData.uploadedBy
                    ? fileData.uploadedBy.firstName + ' ' + fileData.uploadedBy.lastName
                    : 'Mitarbeiter nicht gefunden'}
                  <hr style={{ margin: '0.6rem 0' }} />
                  <strong>Dateiname:</strong> {fileData.file.properties.title}
                </>
              }
              placement="top"
            >
              <a href={fileData.file.publicUrl} target="_blank" rel="noreferrer">
                <u>{truncate(fileData.file.properties.title)}</u>
              </a>
            </Tooltip>
          ) : (
            <a href={fileData.file.publicUrl} target="_blank" rel="noreferrer">
              <u>{truncate(fileData.file.properties.title)}</u>
            </a>
          )}
        </div>
      )}
    </>
  ),
  date: fileData?.tstamp ? dayjs(fileData.tstamp).format('L') : '',
  menu: <ComplaintFilesMenu data={fileData} complaintId={complaintId} complaintFileType={complaintFileType} />,
  comments: fileData?.id && (
    <TableCommentsIcon
      onOpen={addComment.current?.open}
      forType="complaintfiles"
      forId={fileData?.id}
      count={fileData?.comments ? fileData?.comments : 0}
    />
  ),
  visible: fileData?.id && (
    <TableStatusIcon
      onClick={() => {
        if (fileData) {
          onVisibilityChange?.(fileData, !fileData.visibility)
        }
      }}
      className="cursor-pointer"
      status={fileData?.visibility ? 'visible' : 'hidden'}
      tooltip={fileData?.visibility ? t`sichtbar` : t`unsichtbar`}
    />
  ),
  fileAnswer: fileData?.fileAnswer,
  status: fileData?.id && (
    <TableStatus
      status={fileData?.status}
      tooltip={fileData?.status === 1 ? t`Freigegeben` : fileData?.status === 2 ? t`Beantwortet` : t`Nicht freigegeben`}
    />
  )
})

export default generateComplaintStructureItem
