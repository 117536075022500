import { t } from '@lingui/macro'
import React, { useCallback, useImperativeHandle, useMemo, useState } from 'react'
import { Button, Col, Form, Modal, Row } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { TextArea } from '../../../components/Form'
import { CommentTarget, useCreateComment } from '../../../data/remote/Comment'

// eslint-disable-next-line @typescript-eslint/ban-types
export type AddCommentModalProps = {}
export type AddCommentModalRef = {
  open: (target: CommentTarget) => void
}

const AddCommentModal = React.forwardRef<AddCommentModalRef, AddCommentModalProps>(function AddCertificateCodeModal(
  {},
  ref
) {
  const [show, setShow] = useState<CommentTarget>()

  useImperativeHandle(
    ref,
    () => ({
      open(target) {
        setShow(target)
      }
    }),
    []
  )

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset
  } = useForm<{
    content: string
  }>()

  const hideAndReset = useCallback(() => {
    setShow(undefined)
    reset()
  }, [])

  const createComment = useCreateComment()

  const onSubmit = useMemo(
    () =>
      handleSubmit((data) => {
        if (!createComment.running && show) {
          createComment.run(
            {
              for: show,
              ...data
            },
            hideAndReset
          )
        }
      }),
    [createComment, handleSubmit, hideAndReset, show]
  )

  return (
    <Modal show={!!show} centered size="lg" onHide={hideAndReset}>
      <Modal.Header closeButton>
        <Modal.Title>{t`Kommentar hinzufügen`}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={onSubmit} noValidate>
          <Row>
            <Col xs={12}>
              <TextArea
                {...register('content', { required: t`Bitte einen Kommentar eingeben.` })}
                controlId="content"
                name="content"
                label={t`Kommentar`}
                required
                error={errors.content?.message}
              />
            </Col>
          </Row>
          <Row className="mt-3">
            <Col xs={6} className="ml-auto">
              <div className="d-grid">
                <Button variant="primary" type="submit" disabled={createComment.running} size="lg">
                  {t`Kommentar hinzufügen`}
                </Button>
              </div>
            </Col>
          </Row>
        </Form>
      </Modal.Body>
    </Modal>
  )
})

export default AddCommentModal
