import { t } from '@lingui/macro'
import React, { ErrorInfo, ReactNode } from 'react'
// eslint-disable-next-line import/no-unresolved
import LogoSvg from 'jsx:./../assets/logo.svg'

interface ErrorBoundaryProps {
  children: ReactNode
}

interface ErrorBoundaryState {
  hasError: boolean
  error: Error | null
  errorInfo: ErrorInfo | null
}

class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props)
    this.state = {
      hasError: false,
      error: null,
      errorInfo: null
    }
  }

  static getDerivedStateFromError(error: Error): Partial<ErrorBoundaryState> {
    return { hasError: true, error }
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    this.setState({ errorInfo })
    // eslint-disable-next-line no-console
    console.log('Error:', error, errorInfo)
  }

  render(): ReactNode {
    if (this.state.hasError) {
      return (
        <div className="flex items-center justify-center min-h-screen bg-red-100 p-4 d-flex justify-content-center align-items-center h-100 w-75 mx-auto">
          <div className="bg-white p-4 rounded-lg shadow-lg p-6 max-w-2xl w-full w-100">
            <LogoSvg className="d-block mx-auto mb-4" style={{ width: 350 }} />
            <h1 className="text-3xl font-bold text-red-600 mb-4">{t`Hoppla! Es ist ein Fehler aufgetreten.`}</h1>
            <p className="text-lg text-gray-700 mb-4">
              {t`Bitte versuchen Sie es erneut oder kontaktieren Sie den Support.`}
            </p>
            <details className="cursor-pointer">
              <summary className="text-blue-600 hover:text-blue-800">{t`Fehlerdetails anzeigen`}</summary>
              <pre className="mt-2 text-sm text-gray-600 whitespace-pre-wrap overflow-auto max-h-96">
                {this.state.error && this.state.error.toString()}
                <br />
                {this.state.errorInfo && this.state.errorInfo.componentStack}
              </pre>
            </details>
          </div>
        </div>
      )
    }

    return this.props.children
  }
}

export default ErrorBoundary
