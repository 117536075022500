import React, { PropsWithChildren } from 'react'
import { Form } from 'react-bootstrap'
import { UseFormRegisterReturn } from 'react-hook-form'

export type SelectProps = UseFormRegisterReturn &
  PropsWithChildren<{
    controlId: string
    label: string
    labelSrOnly?: boolean
    name: string
    size?: 'sm' | 'lg' | undefined
    groupClass?: string
    labelClass?: string
    inputClass?: string
    error?: string
    readOnly?: boolean
    required?: boolean
    multiple?: boolean
  }>

export const Select = React.forwardRef<HTMLSelectElement, SelectProps>(function Select(
  {
    controlId,
    label,
    labelSrOnly,
    name,
    size = 'lg',
    groupClass,
    labelClass,
    inputClass,
    error,
    readOnly,
    required,
    multiple,
    children,
    ...props
  },
  ref
) {
  return (
    <Form.Group controlId={controlId} className={groupClass}>
      <Form.Label sronly={'true'} className={labelClass}>
        {label}
      </Form.Label>
      <Form.Control
        {...props}
        as="select"
        name={name}
        size={size}
        className={inputClass}
        isInvalid={!!error}
        readOnly={readOnly}
        required={required}
        multiple={multiple}
        ref={ref}
      >
        {children}
      </Form.Control>
      {!!error && <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>}
    </Form.Group>
  )
})
