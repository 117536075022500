import React from 'react'
import { Form, FormControl, FormControlProps, InputGroup } from 'react-bootstrap'

export type InputProps = {
  controlId?: string
  name: string
  label: string
  labelSrOnly?: boolean
  type?: string
  size?: 'sm' | 'lg' | undefined
  placeholder?: string
  groupClass?: string
  labelClass?: string
  inputClass?: string
  error?: string
  readOnly?: boolean
  required?: boolean
  onChange?: FormControlProps['onChange']
  disabled?: boolean
  value?: string | number
  prepend?: React.ReactNode
  append?: React.ReactNode
  min?: number | string
  children?: React.ReactNode
}

export const Input = React.forwardRef<HTMLInputElement, InputProps>(function Input(
  {
    controlId,
    name,
    label,
    labelSrOnly,
    type = 'text',
    size = 'lg',
    placeholder,
    groupClass,
    labelClass,
    inputClass,
    error,
    readOnly,
    required,
    onChange,
    children,
    prepend,
    append,
    ...props
  },
  ref
) {
  let input = (
    <FormControl
      name={name}
      type={type}
      size={size}
      placeholder={placeholder}
      className={inputClass}
      isInvalid={!!error}
      readOnly={readOnly}
      required={required}
      onChange={onChange}
      ref={ref}
      {...props}
    >
      {children}
    </FormControl>
  )

  if (prepend || append) {
    input = (
      <InputGroup size={size}>
        {!!prepend && <InputGroup.Text>{prepend}</InputGroup.Text>}
        {input}
        {!!append && <InputGroup.Text>{append}</InputGroup.Text>}
      </InputGroup>
    )
  }

  return (
    <Form.Group controlId={controlId} className={groupClass}>
      <Form.Label sronly={labelSrOnly} className={labelClass}>
        {!labelSrOnly ? label : null}
      </Form.Label>
      {input}
      {!!error && <FormControl.Feedback type="invalid">{error}</FormControl.Feedback>}
    </Form.Group>
  )
})
