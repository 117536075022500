import { t } from '@lingui/macro'
import React, { useMemo, useState } from 'react'
import DataColumn from '../../../components/ListView/DataColumn'
import { Entrypoint, entrypointNames, SearchSelection } from './Columns'
import SearchFilesTable from './Columns/SearchFilesTable'
import { useCertificateCodesData } from './Columns/useCertificateCodesData'
import { useOrderData } from './Columns/useOrderData'
import { useProductsData } from './Columns/useProductsData'
import { useSupplierData } from './Columns/useSupplierData'

export const Search: React.FC = () => {
  const [entrypoint, setEntrypoint] = useState<Entrypoint>()
  const [selection, setSelection] = useState<SearchSelection>({})

  const setColumnSelection = useMemo(
    () =>
      Object.fromEntries(
        entrypointNames.map((entrypoint) => [
          entrypoint,
          (
            value: number,
            switchToColumn: boolean,
            setFilter?: React.Dispatch<
              React.SetStateAction<{
                search: string
                filter: string
              }>
            >,
            search?: string
          ) => {
            setSelection((selection) => ({ ...selection, [entrypoint]: value }))
            if (switchToColumn) {
              if (setFilter) {
                setFilter((filter) =>
                  !(filter.search || filter.filter) ? { filter: '', search: search ?? filter.search } : filter
                )
              }
              setEntrypoint(entrypoint)
            }
          }
        ])
      ) as {
        [e in Entrypoint]: (
          value: number,
          switchToColumn: boolean,
          setFilter?: React.Dispatch<
            React.SetStateAction<{
              search: string
              filter: string
            }>
          >,
          search?: string
        ) => void
      },
    []
  )

  const supplierData = useSupplierData({
    selection,
    setSelection,
    entrypoint,
    setEntrypoint,
    setColumnSelection: setColumnSelection.suppliers
  })
  const orderData = useOrderData({
    selection,
    setSelection,
    entrypoint,
    setEntrypoint,
    setColumnSelection: setColumnSelection.orders
  })
  const productsData = useProductsData({
    selection,
    setSelection,
    entrypoint,
    setEntrypoint,
    setColumnSelection: setColumnSelection.products
  })
  const certificateCodesData = useCertificateCodesData({
    selection,
    setSelection,
    entrypoint,
    setEntrypoint,
    setColumnSelection: setColumnSelection.certificatecodes
  })

  return (
    <>
      <DataColumn title={t`Lieferanten`} {...supplierData} />
      <DataColumn title={t`Bestellungen`} {...orderData} />
      <DataColumn title={t`Artikel`} {...productsData} />
      <DataColumn title={t`Zeugniscode / Schmelzennr.`} {...certificateCodesData} />
      <SearchFilesTable certificateCode={certificateCodesData.current} />
    </>
  )
}
