import { t } from '@lingui/macro'
import { useMemo, useRef, useState } from 'react'
import { Button } from 'react-bootstrap'
import DataTable, { dynamicRows, sectionHeader } from '../../../../components/DataTable/DataTable'
import { useCanReadComments } from '../../../../data/remote/Comment'
import {
  useCanManageComplaints,
  useComplaintFiles,
  useSetComplaintStatus,
  useUpdateComplaintFileVisibility
} from '../../../../data/remote/Complaint'
import { useUploadFiles } from '../../../../data/remote/FileData'
import { InternalFileData, useUpdateInternalFileVisibility } from '../../../../data/remote/InternalFile'

import { ComplaintData, ComplaintFileData } from '../../../../types/ComplaintDataTypes'

import AddCommentModal, { AddCommentModalRef } from '../../Certificates/AddCommentModal'
import generateComplaintStructureItem from './generateComplaintStructureItem'
import generateInternalFilesTableRow from './generateInternalFilesTableRow'
import { Loading } from '../../Loading'
import { useAuthentication } from '../../../../data/remote/User'
import { HTMLCheckbox } from '../../../../components/Checkbox'

type ComplaintFilesTableProps = {
  complaint: ComplaintData
}

const ComplaintFilesTable: React.FC<ComplaintFilesTableProps> = ({ complaint }) => {
  const { run: updateComplaintFileVisibility, running: updatingVisibility } = useUpdateComplaintFileVisibility()
  const { run: updateInternalFileVisibility, running: updatingInternalFileVisibility } =
    useUpdateInternalFileVisibility()
  const addComment = useRef<AddCommentModalRef>(null)
  const { data: complaintFiles, loading: loadingComplaintFiles } = useComplaintFiles({ complaintId: complaint.id })

  const canManageComplaints = useCanManageComplaints()
  const canReadComments = useCanReadComments()

  const [uploadInternalFile, canUploadInternalFiles] = useUploadFiles({
    type: 'internalfile',
    complaintId: complaint.id
  })

  // actions
  const handleVisibilityChange = useMemo(
    () => (complaintFile: ComplaintFileData, visible: boolean) => {
      if (updatingVisibility || !complaintFile.id) return
      updateComplaintFileVisibility({
        complaintId: complaint.id,
        complaintFileId: complaintFile.id,
        visibility: visible ? 1 : 0
      })
    },
    [updatingVisibility, updateComplaintFileVisibility, complaint.id]
  )

  const handleVisibilityChangeInternal = useMemo(
    () => (internalFile: InternalFileData, visible: boolean) => {
      if (updatingInternalFileVisibility) return
      updateInternalFileVisibility({
        id: internalFile.id,
        public: visible
      })
    },
    [updatingInternalFileVisibility, updateInternalFileVisibility]
  )

  const columns = useMemo(
    () => ({
      name: t`Dateiname`,
      date: t`Änderungsdatum`,
      comments: '',
      status: t`Status`,
      visible: t`Sichtbar`,
      menu: ''
    }),
    []
  )

  const columnClasses = useMemo(
    () => ({
      name: 'col-4 complaint-table-col',
      date: 'col-3 complaint-table-col',
      comments: 'col-1 complaint-table-col',
      status: 'col-2 complaint-table-col',
      visible: 'col-1 complaint-table-col',
      menu: 'col-1 text-right'
    }),
    []
  )

  const complaintFixedStructure = useMemo(
    () => [
      ['deviation', 'Abweichungsprüfung'],
      ['caq', 'CAQ-Bericht'],
      ['4d', '4D/8D-Bericht'],
      ['cost', 'Kostenerfassung'],
      ['action_plan', 'Action Plan']
    ],
    []
  )

  // check if complaint has all filetypes and if not add them as empty array

  if (complaintFiles) {
    if (canManageComplaints) {
      complaintFixedStructure.forEach((fileType) => {
        if (!complaintFiles.find((complaintFile) => complaintFile.complaintFileType === fileType[0])) {
          complaintFiles.push({
            complaintId: complaint.id,
            complaintFileType: fileType[0],
            complaintFileTypeLabel: fileType[1]
          })
        }
      })
    }
    // keep sorting of complaintFixedStructure
    complaintFiles.sort((a, b) => {
      return (
        complaintFixedStructure.findIndex((fileType) => fileType[0] === a.complaintFileType) -
        complaintFixedStructure.findIndex((fileType) => fileType[0] === b.complaintFileType)
      )
    })
  }

  const isSupplier = useAuthentication().user?.isSupplier ?? true

  const [complaintIsEligible, setComplaintIsEligible] = useState(complaint.isEligibleForRating)

  const complaintStructure =
    complaintFiles &&
    complaintFiles.map((complaintFile) =>
      generateComplaintStructureItem({
        nameText: t`${complaintFile.complaintFileTypeLabel}`,
        complaintFileType: complaintFile.complaintFileType,
        addComment,
        fileData: complaintFile,
        complaintId: complaint.id,
        onVisibilityChange: handleVisibilityChange,
        fileAnswer: complaintFile.fileAnswer,
        isSupplier
      })
    )

  const internalStructure = useMemo(() => {
    if (!complaint.internalFiles) return []
    return complaint.internalFiles.map((internalFile) =>
      generateInternalFilesTableRow({
        addComment,
        canManage: canManageComplaints,
        fileData: internalFile,
        complaintId: complaint.id,
        onVisibilityChange: handleVisibilityChangeInternal,
        isClosed: complaint.status === 1
      })
    )
  }, [canManageComplaints, complaint.id, complaint.internalFiles, complaint.status, handleVisibilityChangeInternal])

  const items = useMemo(
    () => [
      ...dynamicRows(complaintStructure, false, t`Für diese Reklamation wurden noch keine Dateien hochgeladen`),

      ...sectionHeader({
        title: canUploadInternalFiles ? t`Interne Dateien` : t`Geteilte Dateien`,
        newLabel: canUploadInternalFiles && complaint.status !== 1 ? t`Neue interne Datei hochladen` : undefined,
        onNew: canUploadInternalFiles ? uploadInternalFile : undefined
      }),
      ...dynamicRows(internalStructure, false, t`Für diese Reklamation wurden noch keine internen Dateien hochgeladen`)
    ],
    [canUploadInternalFiles, complaint.status, complaintStructure, internalStructure, uploadInternalFile]
  )

  const setComplaintStatus = useSetComplaintStatus(complaint.id)
  const hiddenColumns = useMemo(() => {
    const hiddenColumnsList = canReadComments ? [] : ['comments']
    if (!canManageComplaints) {
      hiddenColumnsList.push('visible')
    }
    return hiddenColumnsList
  }, [canReadComments, canManageComplaints])

  return (
    <>
      {loadingComplaintFiles ? (
        <Loading />
      ) : (
        <>
          <div className="d-flex flex-grow-1 flex-column">
            <DataTable
              columns={columns}
              items={items}
              columnClasses={columnClasses}
              hiddenColumns={hiddenColumns}
              emails={{ count: complaint.emails, complaint }}
            >
              {(file) => ({
                name: file.name,
                date: file.date,
                comments: file.comments,
                status: file.status,
                visible: file.visible,
                menu: file.menu,
                // @ts-ignore
                fileAnswer: file.fileAnswer
              })}
            </DataTable>
          </div>
          {canManageComplaints &&
            (complaint.status === 0 ? (
              <div className="p-1 bg-white d-flex flex-column align-items-center complaint__start">
                <Button size="lg" className="px-5" onClick={() => setComplaintStatus.setStatus(3, complaintIsEligible)}>
                  {t`Reklamation starten`}
                </Button>
              </div>
            ) : complaint.status === 1 ? (
              <div className="p-1 bg-white d-flex flex-column align-items-center complaint__start">
                <div className="d-flex row">
                  <div className="flex-grow-1 disabled">
                    <HTMLCheckbox
                      value={complaintIsEligible}
                      id={`complaint-${complaint.id}-option-1`}
                      className="custom-control-input"
                    />
                    <Button
                      className="w-100 btn-checkbox-toggle mb-0 disabled"
                      disabled={true}
                      as="label"
                      variant="outline-light text-primary"
                      size="lg"
                      htmlFor={`complaint-${complaint.id}-option-1`}
                    >
                      <span>{t`Reklamation berechtigt`}</span>
                    </Button>
                  </div>
                  <Button
                    size="lg"
                    className="px-5 ml-3"
                    onClick={() => setComplaintStatus.setStatus(3, complaintIsEligible)}
                  >
                    {t`Reklamation öffnen`}
                  </Button>
                </div>
              </div>
            ) : (
              <div className="p-1 bg-white d-flex flex-column align-items-center complaint__start">
                <div className="d-flex row">
                  <div className="flex-grow-1">
                    <HTMLCheckbox
                      value={complaintIsEligible}
                      id={`complaint-${complaint.id}-option-1`}
                      className="custom-control-input"
                      onChange={(checked) => setComplaintIsEligible(checked)}
                    />
                    <Button
                      className="w-100 btn-checkbox-toggle mb-0"
                      as="label"
                      variant="outline-light text-primary"
                      size="lg"
                      htmlFor={`complaint-${complaint.id}-option-1`}
                    >
                      <span>{t`Reklamation berechtigt`}</span>
                    </Button>
                  </div>
                  <Button
                    size="lg"
                    className="px-5 ml-3"
                    onClick={() => setComplaintStatus.setStatus(1, complaintIsEligible)}
                  >
                    {t`Reklamation abschließen`}
                  </Button>
                </div>
              </div>
            ))}

          <AddCommentModal ref={addComment} />
        </>
      )}
    </>
  )
}
export default ComplaintFilesTable
