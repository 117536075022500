import { t } from '@lingui/macro'
import { Icon } from '../../../../components/Icon'
import { useEmails } from '../../../../data/remote/Email'
import Paperclip from 'jsx:./../../../../assets/icons/paperclip.svg'
import CloudDownload from 'jsx:./../../../../assets/icons/cloud-download.svg'
import { truncate } from 'lodash'
import { ComplaintData } from '../../../../types/ComplaintDataTypes'
import { Loading } from '../../Loading'
import React from 'react'

type ComplaintEmailsTableProps = {
  complaint: ComplaintData
}

const ComplaintEmailsTable: React.FC<ComplaintEmailsTableProps> = ({ complaint }) => {
  const downloadEmail = (link: string, name: string) => {
    const linkElement = document.createElement('a')
    linkElement.href = link // replace with the actual file URL
    linkElement.download = name // replace with the desired filename
    document.body.appendChild(linkElement)
    linkElement.click()
    document.body.removeChild(linkElement)
  }

  // email data
  const { data: emails } = useEmails({
    for: {
      type: 'complaints',
      id: complaint.id
    }
  })

  return (
    <div>
      {emails && emails.length > 0 ? (
        <table className="data-table">
          <thead>
            <tr>
              <th className="col-2">
                <div>{t`Absender`}</div>
              </th>
              <th className="col-1">
                <div>{t`Datum`}</div>
              </th>
              <th className="col-3">
                <div>{t`Betreff`}</div>
              </th>
              <th className="col-1">
                <div></div>
              </th>
            </tr>
          </thead>
          <tbody>
            {emails?.map((email) => (
              <tr key={email.id}>
                <td className="col-2">{email.sender}</td>
                <td className="col-1">{new Date(email.crdate).toLocaleDateString()}</td>
                <td className="col-3">
                  <div>
                    <span className="mr-1">{truncate(email.subject, { length: 30 })}</span>
                    {email.hasAttachment ? <Icon svg={Paperclip} /> : ''}
                  </div>
                </td>
                <td className="col-1 text-right">
                  <button
                    className="btn p-0"
                    style={{ fontSize: '1.8rem' }}
                    onClick={() => downloadEmail(email.file.publicUrl, email.file.properties.filename)}
                  >
                    <Icon svg={CloudDownload} />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <Loading />
      )}
    </div>
  )
}

export default ComplaintEmailsTable
