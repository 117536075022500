import { t } from '@lingui/macro'
import dayjs from 'dayjs'
import React, { useCallback, useMemo, useRef } from 'react'

import DataTable, { DataTableItem, dynamicRows, sectionHeader } from '../../../../components/DataTable/DataTable'
import DotsMenu from '../../../../components/DotsMenu'
import { DropdownItem, DropdownSeperator } from '../../../../components/Overlays/Dropdown'
import { useDeleteFile, useReplaceFile, useUploadFiles } from '../../../../data/remote/FileData'
import { RegulationFileData } from '../../../../data/remote/RegulationFile'
import ChangeFileModal, { ChangeFileModalRef } from '../Modals/ChangeFileModal'
import { intervalString } from '../../../../scripts/utils'

export type RegulationFilesTableProps = {
  data?: RegulationFileData[]
  loading?: boolean
  folderId?: number
}

const RegulationFileMenu: React.FC<{
  item: RegulationFileData
  changeInterval: React.RefObject<ChangeFileModalRef>
}> = ({ item, changeInterval }) => {
  const [replaceFile, canReplaceFile] = useReplaceFile({
    type: 'regulationfile',
    fileId: item.id
  })
  const [deleteFile, canDeleteFile] = useDeleteFile({
    type: 'regulationfile',
    fileId: item.id
  })

  return (
    <DotsMenu>
      <DropdownItem href={item.file.publicUrl}>{t`Datei öffnen`}</DropdownItem>
      <DropdownSeperator />
      <DropdownItem
        onClick={() => {
          changeInterval.current?.open(item)
        }}
      >{t`Datei bearbeiten`}</DropdownItem>
      {canReplaceFile && <DropdownItem onClick={replaceFile}>{t`Datei ersetzen`}</DropdownItem>}
      {canDeleteFile && <DropdownItem textColor="danger" onClick={deleteFile}>{t`Datei löschen`}</DropdownItem>}
    </DotsMenu>
  )
}

const RegulationFilesTable: React.FC<RegulationFilesTableProps> = ({ data, loading, folderId = -1 }) => {
  const columns = useMemo(
    () => ({
      name: t`Dateiname`,
      interval: t`Intervall`,
      date: t`Änderungsdatum`,
      menu: ''
    }),
    []
  )

  const [uploadFiles, canUploadFiles] = useUploadFiles({
    type: 'regulationfile',
    folderId
  })

  const items = useMemo<DataTableItem<RegulationFileData>[]>(
    () => [
      ...(canUploadFiles ? sectionHeader({ newLabel: t`Dateien hinzufügen`, onNew: uploadFiles }) : []),
      ...dynamicRows(data, loading, t`Für diesen Ordner wurden noch keine Dateien hochgeladen.`)
    ],
    [canUploadFiles, data, loading, uploadFiles]
  )

  const columnClasses = useMemo(
    () => ({
      menu: 'text-right'
    }),
    []
  )

  const changeInterval = useRef<ChangeFileModalRef>(null)

  return (
    <>
      <DataTable items={items} columns={columns} columnClasses={columnClasses}>
        {useCallback(
          (item: RegulationFileData) => ({
            name: (
              <a href={item.file.publicUrl} target="_blank" className="text-break" rel="noreferrer">
                {item.file.properties.title}
              </a>
            ),
            interval: item.interval ? intervalString(item.interval) : '',
            date: dayjs(item.tstamp).format('L'),
            menu: <RegulationFileMenu item={item} changeInterval={changeInterval} />
          }),
          []
        )}
      </DataTable>
      <ChangeFileModal ref={changeInterval} />
    </>
  )
}

export default RegulationFilesTable
