import { useState, useEffect, useMemo, useCallback } from 'react'
import { t } from '@lingui/macro'
import { SupplierData, useSupplierRating } from '../../../data/remote/Supplier'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { ArcElement, Tooltip, Legend, Chart as ChartJS } from 'chart.js'
import { Pie } from 'react-chartjs-2'
import { Col } from 'react-bootstrap'
import { DateRange, DefinedRange, Range, RangeKeyDict, StaticRange } from 'react-date-range'
import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'
import { de, enGB } from 'date-fns/locale'
import { isSameDay, subMonths } from 'date-fns'
import { BLUE_LIGHT } from '../../../scripts/styles'
import { useCurrentLanguage } from '../../../data/remote/Language'
import { ContainerBox } from '../../../components/Layout/ContainerBox'

ChartJS.register(ArcElement, Tooltip, Legend)

const formatter = new Intl.DateTimeFormat('de-DE', {
  day: '2-digit',
  month: '2-digit',
  year: 'numeric'
})

const MIN_YEAR_DATE = new Date(2024, 0, 1)

type SupplierRatingProps = {
  supplierData: SupplierData
}
interface CustomStaticRange extends Omit<StaticRange, 'range'> {
  range: () => { startDate: Date; endDate: Date }
  isSelected: (range: Range) => boolean
}

export default function SupplierRatingTable({ supplierData }: SupplierRatingProps) {
  // Calculate the min allowed date (registration date or 2024-01-01, whichever is later)
  const minAllowedDate = useMemo(() => {
    const registrationDate = new Date(supplierData.createdAt)
    return registrationDate < MIN_YEAR_DATE ? MIN_YEAR_DATE : registrationDate
  }, [supplierData.createdAt])

  // Calculate initial start date for last 12 months
  const getLastTwelveMonthsStart = useCallback(() => {
    let startDate = subMonths(new Date(), 12)

    // Apply the registration date constraint
    const registrationDate = new Date(supplierData.createdAt)
    if (startDate < registrationDate) {
      startDate = registrationDate
    }

    if (startDate < MIN_YEAR_DATE) {
      startDate = MIN_YEAR_DATE
    }

    return startDate
  }, [supplierData.createdAt])

  const [dateRange, setDateRange] = useState<Range[]>([
    {
      startDate: getLastTwelveMonthsStart(),
      endDate: new Date(),
      key: 'selection'
    }
  ])

  const supplierId = supplierData.id

  const { data, loading, reload } = useSupplierRating({
    supplierId,
    startDate: dateRange[0].startDate ? dateRange[0].startDate.toISOString().split('T')[0] : '',
    endDate: dateRange[0].endDate ? dateRange[0].endDate.toISOString().split('T')[0] : ''
  })

  useEffect(() => {
    reload()
  }, [dateRange, reload, supplierId])

  const handleSelect = (rangesByKey: RangeKeyDict) => {
    const selectedRange = rangesByKey.selection
    if (selectedRange) {
      // Ensure selected date is not before minAllowedDate
      const startDate = selectedRange.startDate
        ? selectedRange.startDate < minAllowedDate
          ? minAllowedDate
          : selectedRange.startDate
        : minAllowedDate
      setDateRange([{ ...selectedRange, startDate }])
    }
  }

  const [supplierCreatedAtYear, setSupplierCreatedAtYear] = useState<number | null>(null)

  useEffect(() => {
    if (!supplierData?.createdAt) return
    setSupplierCreatedAtYear(new Date(supplierData.createdAt).getFullYear())
  }, [supplierData?.createdAt])

  const currentYear = new Date().getFullYear()

  const customStaticRanges: CustomStaticRange[] = useMemo(() => {
    const ranges: CustomStaticRange[] = [
      {
        label: t`Letzten 12 Monate`,
        range: () => ({
          startDate: getLastTwelveMonthsStart(),
          endDate: new Date()
        }),
        isSelected(range) {
          // Nur wenn beide Daten exakt übereinstimmen
          const definedRange = this.range()
          return (
            isSameDay(definedRange.startDate, range.startDate ? new Date(range.startDate) : new Date()) &&
            isSameDay(definedRange.endDate, range.endDate ? new Date(range.endDate) : new Date())
          )
        }
      }
    ]

    if (!supplierCreatedAtYear) return ranges

    const minYear = minAllowedDate.getFullYear()
    for (let year = currentYear; year >= minYear; year--) {
      ranges.push({
        label: year.toString(),
        range: () => ({
          startDate: year === supplierCreatedAtYear ? getLastTwelveMonthsStart() : new Date(`${year}-01-01`),
          endDate: new Date(`${year}-12-31`)
        }),
        isSelected(range: Range) {
          return (
            isSameDay(this.range().startDate, range.startDate ? new Date(range.startDate) : new Date()) &&
            isSameDay(this.range().endDate, range.endDate ? new Date(range.endDate) : new Date())
          )
        }
      })
    }

    return ranges
  }, [supplierCreatedAtYear, minAllowedDate, getLastTwelveMonthsStart, currentYear])

  const currentLanguage = useCurrentLanguage()

  const dateRangeLanguage = currentLanguage?.twoLetterIsoCode === 'de' ? de : enGB

  if (
    new Date(supplierData!.createdAt).getFullYear() < currentYear ||
    (data?.rating.orderPositionCount && data?.rating.orderPositionCount > 0)
  ) {
    return (
      <ContainerBox>
        <div className="supplier-rating">
          <div className="d-flex justify-content-between align-items-center mb-2">
            <h2>{t`Lieferantenbewertung`}</h2>
          </div>

          <div className="row">
            <Col xl={5} lg={5} md={5} sm={12} xs={12}>
              <div className="d-flex justify-content-between">
                {supplierCreatedAtYear && (
                  <>
                    <DefinedRange
                      inputRanges={[]}
                      onChange={handleSelect}
                      ranges={dateRange}
                      staticRanges={customStaticRanges}
                    />
                    <DateRange
                      onChange={handleSelect}
                      ranges={dateRange}
                      minDate={minAllowedDate}
                      maxDate={new Date()}
                      months={1}
                      direction="horizontal"
                      locale={dateRangeLanguage}
                      rangeColors={['#1b2149']}
                    />
                  </>
                )}
              </div>
            </Col>
            {data?.rating.orderPositionCount === 0 ? (
              <Col xl={7} lg={7} md={7} sm={12} xs={12}>
                <div className="alert alert-info" role="alert">
                  {t`Es gibt keine Daten für den ausgewählten Zeitraum`}
                </div>
              </Col>
            ) : null}

            <Col xl={3} lg={3} md={3} sm={12} xs={12}>
              {loading || !data ? (
                <Skeleton width={300} height={300} />
              ) : data.rating.orderPositionCount !== 0 ? (
                <Pie
                  data={{
                    labels: [t`in Ordnung`, t`nicht in Ordnung`],
                    datasets: [
                      {
                        data: [
                          data?.rating.orderPositionCount - data?.rating.complaintCount < 0
                            ? 0
                            : data?.rating.orderPositionCount - data?.rating.complaintCount,
                          data?.rating.complaintCount
                        ],
                        backgroundColor: ['#1b2149', '#c00d0d'],
                        hoverBackgroundColor: ['#1b2149', '#c00d0d']
                      }
                    ]
                  }}
                  options={{
                    plugins: {
                      legend: {
                        position: 'bottom'
                      }
                    }
                  }}
                />
              ) : null}
            </Col>
            {loading || !data ? (
              <Skeleton count={4} width={400} height={60} />
            ) : data.rating?.orderPositionCount !== 0 ? (
              <Col xl={4} lg={4} md={4} sm={12} xs={12}>
                <table className="table-ratings table table-striped table-bordered">
                  <thead style={{ backgroundColor: BLUE_LIGHT }}>
                    <tr>
                      <td colSpan={2} style={{ textAlign: 'center' }}>
                        {data.rating.startDate && data.rating.endDate
                          ? `${formatter.format(new Date(data.rating.startDate))} - ${formatter.format(
                              new Date(data.rating.endDate)
                            )}`
                          : `${formatter.format(dateRange[0].startDate)} - ${formatter.format(dateRange[0].endDate)}`}
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr style={{ lineHeight: 3, backgroundColor: 'white' }}>
                      <td>{t`Qualität`}</td>
                      <td>
                        {data.rating?.class} |{' '}
                        {data.rating?.value !== 100 ? (data.rating?.value * 100).toFixed(2) : 100}%
                      </td>
                    </tr>
                    <tr style={{ lineHeight: 3, backgroundColor: 'white' }}>
                      <td>{t`Bestellpositionen`}</td>
                      <td>{data.rating?.orderPositionCount}</td>
                    </tr>
                    <tr style={{ lineHeight: 3, backgroundColor: 'white' }}>
                      <td>{t`Bestellpositionen in Ordnung`}</td>
                      <td>
                        {data.rating.orderPositionCount - data.rating.complaintCount < 0
                          ? 0
                          : data.rating.orderPositionCount - data.rating.complaintCount}
                      </td>
                    </tr>
                    <tr style={{ lineHeight: 3, backgroundColor: 'white' }}>
                      <td>{t`Bestellpositionen nicht in Ordnung`}</td>
                      <td>{data.rating.complaintCount}</td>
                    </tr>
                  </tbody>
                </table>
              </Col>
            ) : null}
          </div>
        </div>
      </ContainerBox>
    )
  } else {
    return null
  }
}
