import { t } from '@lingui/macro'
import React, { useMemo } from 'react'
import { Alert, Col, Form, FormControl, Row } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { Icon } from '../../../components/Icon'
import LocalizedLink from '../../../components/LocalizedLink'
import LoginButton from '../LoginButton'
import SquareLeft from 'jsx:../../../assets/icons/square-left.svg'
import { useRequestPasswordResetEmail } from '../../../data/remote/User'

const RequestEmail: React.FC = () => {
  const { run: requestPasswordResetEmail, running, error, result } = useRequestPasswordResetEmail()
  const { register, handleSubmit, formState } = useForm<{ email: string }>()
  const onSubmit = useMemo(
    () => handleSubmit((input) => requestPasswordResetEmail(input)),
    [handleSubmit, requestPasswordResetEmail]
  )

  let content = (
    <Form onSubmit={onSubmit} className="mt-3" noValidate>
      <Form.Group controlId="forgot-password-email">
        <Form.Label sronly={'true'}>{t`E-Mail-Adresse`}</Form.Label>
        <Form.Control
          type="text"
          placeholder={t`E-Mail-Adresse`}
          size="lg"
          className="form-control-login"
          {...register('email', {
            required: t`Bitte geben Sie Ihre E-Mail-Adressen ein.`
          })}
          isInvalid={!!formState.errors.email}
        />
        {formState.errors.email && (
          <FormControl.Feedback type="invalid">{formState.errors.email.message}</FormControl.Feedback>
        )}
      </Form.Group>
      {error && (
        <Alert variant="danger">
          <Alert.Heading>{t`Fehler beim Zurücksetzen des Passworts`}</Alert.Heading>
          {error?.response?.data?.message}
        </Alert>
      )}
      <Row>
        <Col md={6}>
          <LoginButton className="mt-3" disabled={running}>
            {t`E-Mail anfordern`}
          </LoginButton>
        </Col>
      </Row>
    </Form>
  )

  if (result?.data.code === 200) {
    content = (
      <Alert variant="success">
        <Alert.Heading>{t`Anfrage erfolgreich`}</Alert.Heading>
        {t`Bitte schauen Sie in Ihren Emails nach.`}
      </Alert>
    )
  }

  return (
    <>
      <h1>{t`Passwort vergessen`}</h1>
      <p>
        {t`Sollten Sie Ihren Zugang vergessen haben, können Sie hier eine E-Mail zum Zurücksetzen Ihres Passworts anfordern.`}
      </p>
      {content}
      <Row className="mt-3">
        <Col>
          <LocalizedLink to="/" className="large font-weight-bold">
            <Icon svg={SquareLeft} className="icon-left" />
            {t`Zurück zum Login`}
          </LocalizedLink>
        </Col>
      </Row>
    </>
  )
}

export default RequestEmail
