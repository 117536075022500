import { t } from '@lingui/macro'
import dayjs from 'dayjs'
import React, { useCallback, useMemo, useRef } from 'react'
import DataTable, { DataTableItem, dynamicRows, sectionHeader } from '../../../../components/DataTable/DataTable'
import TableCommentsIcon from '../../../../components/DataTable/TableCommentsIcon'
import TableStatusIcon from '../../../../components/DataTable/TableStatusIcon'
import {
  CertificateDataMinimal,
  getStatusText,
  useCertificates,
  useDeleteCertificate,
  useUpdateCertificateApproval
} from '../../../../data/remote/Certificate'
import { CertificateCodeDataMinimal, useSetCertificateCodeApproval } from '../../../../data/remote/CertificateCode'
import { useUploadFiles } from '../../../../data/remote/FileData'
import { InternalFileDataMinimal, useInternalFiles } from '../../../../data/remote/InternalFile'
import AddCommentModal, { AddCommentModalRef } from '../AddCommentModal'
import { DropdownItem, DropdownSeperator } from '../../../../components/Overlays/Dropdown'
import { CommentTarget, useCanWriteComments } from '../../../../data/remote/Comment'
import DotsMenu from '../../../../components/DotsMenu'
import { Button } from 'react-bootstrap'
import { useConfirm } from '../../../../navigation/Notifications'
import { useAuthentication } from '../../../../data/remote/User'
import InternalFileMenu from '../../Files/InternalFilesMenu'

export type CertificateFilesTableProps = {
  certificateCode: CertificateCodeDataMinimal
}

type ItemType = CertificateDataMinimal | InternalFileDataMinimal

const isCertificate = (item: ItemType): item is CertificateDataMinimal => {
  return !('type' in item) && 'status' in item
}

const isInternalFile = (item: ItemType): item is InternalFileDataMinimal => {
  return !('type' in item) && !('status' in item)
}

const CertificateMenu: React.FC<{
  certificate: CertificateDataMinimal
  certificateCode: CertificateCodeDataMinimal
  addComment?: (target: CommentTarget) => void
  canComment: boolean
  certificateCodeStatus: CertificateCodeDataMinimal['status']['approval']
}> = ({ certificate, certificateCode, canComment, addComment, certificateCodeStatus }) => {
  const addCommentClick = useCallback(() => {
    addComment?.({
      type: 'certificates',
      id: certificate.id
    })
  }, [addComment, certificate.id])

  const updateCertificateApproval = useUpdateCertificateApproval(certificate.id)

  const deleteCertificate = useDeleteCertificate()
  const confirm = useConfirm()

  const handleDeleteCertificate = () => {
    confirm({
      title: t`Zeugnis löschen?`,
      text: t`Soll das Zeugnis wirklich gelöscht werden?`,
      options: [
        { label: t`Abbrechen`, type: 'secondary' },
        {
          label: t`Zeugnis löschen`,
          type: 'danger',
          onClick: () => deleteCertificate.run({ codeId: certificateCode.id, certificateId: certificate.id })
        }
      ]
    })
  }

  return (
    <DotsMenu>
      {updateCertificateApproval.actionAllowed && certificateCodeStatus !== 'closed' && (
        <>
          <DropdownItem
            key="accept"
            onClick={updateCertificateApproval.approve}
            disabled={certificate.status?.approval === 'approved'}
          >{t`Zeugnis freigeben`}</DropdownItem>
          <DropdownItem
            key="decline"
            onClick={updateCertificateApproval.deny}
            disabled={certificate.status?.approval === 'denied'}
          >{t`Zeugnis ablehnen`}</DropdownItem>
          <DropdownSeperator key="divider" />
        </>
      )}
      {canComment && <DropdownItem key="comment" onClick={addCommentClick}>{t`Kommentar hinzufügen`}</DropdownItem>}
      <DropdownItem href={certificate.file.publicUrl} key="download">{t`Zeugnis öffnen`}</DropdownItem>
      {certificateCodeStatus !== 'closed' && !certificate.status.locked && (
        <DropdownItem key="delete" textColor="danger" onClick={handleDeleteCertificate}>
          Löschen
        </DropdownItem>
      )}
    </DotsMenu>
  )
}

const CertificateFilesTable: React.FC<CertificateFilesTableProps> = ({ certificateCode }) => {
  const showComments = useCanWriteComments()
  const isSupplier = !!useAuthentication().user?.isSupplier

  const addComment = useRef<AddCommentModalRef>(null)
  const { data: certificates, loading: certificatesLoading } = useCertificates(
    useMemo(
      () => ({
        codeId: certificateCode.id
      }),
      [certificateCode.id]
    )
  )

  const { data: internalFiles, loading: internalFilesLoading } = useInternalFiles({ codeId: certificateCode.id })

  const columns = useMemo(
    () => ({
      name: t`Dateiname`,
      date: t`Änderungsdatum`,
      comments: '',
      status: t`Status`,
      menu: ''
    }),
    []
  )

  const columnClasses = useMemo(
    () => ({
      menu: 'text-right'
    }),
    []
  )

  const [uploadCertificate, canUploadCertificates] = useUploadFiles({
    type: 'certificate',
    certificateCodeId: certificateCode.id
  })

  const [uploadInternalFile, canUploadInternalFiles] = useUploadFiles({
    type: 'internalfile',
    certificateCodeId: certificateCode.id
  })

  const setCertificateCodeApproval = useSetCertificateCodeApproval(certificateCode.id)
  const confirm = useConfirm()
  const openCertificate = useCallback(() => {
    confirm({
      title: t`Zeugniscode öffnen?`,
      text: t`Soll der Zeugniscode ${certificateCode.code} wirklich geöffnet werden?`,
      options: [
        { label: t`Abbrechen`, type: 'secondary' },
        { label: t`Zeugniscode öffnen`, type: 'primary', onClick: setCertificateCodeApproval.open }
      ]
    })
  }, [certificateCode.code, confirm, setCertificateCodeApproval.open])
  const closeCertificate = useCallback(() => {
    confirm({
      title: t`Zeugniscode schließen?`,
      text: t`Soll der Zeugniscode ${certificateCode.code} wirklich geschlossen werden?`,
      options: [
        { label: t`Abbrechen`, type: 'secondary' },
        { label: t`Zeugniscode schließen`, type: 'danger', onClick: setCertificateCodeApproval.close }
      ]
    })
  }, [certificateCode.code, confirm, setCertificateCodeApproval.close])

  const items = useMemo<DataTableItem<ItemType>[]>(
    () => [
      ...(canUploadCertificates && certificateCode.status.approval !== 'closed'
        ? sectionHeader({
            newLabel: t`Neues Zertifikat hochladen`,
            onNew: uploadCertificate
          })
        : []),
      ...dynamicRows(
        certificates,
        certificatesLoading,
        t`Für diesen Zeugniscode wurden noch keine Dateien hochgeladen`
      ),
      ...(canUploadInternalFiles || (internalFiles && internalFiles.length)
        ? [
            ...sectionHeader({
              title: canUploadInternalFiles ? t`Interne Dateien` : t`Geteilte Dateien`,
              newLabel: canUploadInternalFiles ? t`Neue interne Datei hochladen` : undefined,
              onNew: canUploadInternalFiles ? uploadInternalFile : undefined
            }),
            ...dynamicRows(
              internalFiles,
              internalFilesLoading,
              t`Für diesen Zeugniscode wurden noch keine internen Dateien hochgeladen`
            )
          ]
        : [])
    ],
    [
      canUploadCertificates,
      canUploadInternalFiles,
      certificateCode.status.approval,
      certificates,
      certificatesLoading,
      internalFiles,
      internalFilesLoading,
      uploadCertificate,
      uploadInternalFile
    ]
  )

  return (
    <>
      <div className="d-flex flex-grow-1">
        <DataTable
          items={items}
          columns={columns}
          columnClasses={columnClasses}
          hiddenColumns={useMemo(() => (showComments ? [] : ['comments']), [showComments])}
        >
          {useCallback(
            (item: ItemType) =>
              isCertificate(item)
                ? {
                    name: (
                      <a href={item.file.publicUrl} target="_blank" className="text-break" rel="noreferrer">
                        {item.file.properties.title}
                      </a>
                    ),
                    date: dayjs(item.tstamp).format('L'),
                    comments: (
                      <TableCommentsIcon
                        onOpen={addComment.current?.open}
                        forType="certificates"
                        forId={item.id}
                        count={item.comments}
                      />
                    ),
                    status:
                      isSupplier &&
                      item.status.approval === 'approved' &&
                      certificateCode.status.pending ? undefined : (
                        <TableStatusIcon
                          isMandatory={certificateCode.status.mandatory}
                          status={item.status.approval}
                          tooltip={getStatusText(item.status)}
                        />
                      ),
                    menu: (
                      <CertificateMenu
                        certificate={item}
                        certificateCode={certificateCode}
                        addComment={addComment.current?.open}
                        canComment={showComments}
                        certificateCodeStatus={certificateCode.status.approval}
                      />
                    )
                  }
                : isInternalFile(item)
                ? {
                    name: (
                      <a href={item.file.publicUrl} target="_blank" className="text-break" rel="noreferrer">
                        {item.file.properties.title}
                      </a>
                    ),
                    date: dayjs(item.tstamp).format('L'),
                    comments: (
                      <TableCommentsIcon
                        onOpen={addComment.current?.open}
                        forType="internalfiles"
                        forId={item.id}
                        count={item.comments}
                      />
                    ),
                    status: canUploadInternalFiles ? (
                      <TableStatusIcon
                        isMandatory={certificateCode.status.mandatory}
                        status={item.public ? 'visible' : 'hidden'}
                        tooltip={item.public ? t`sichtbar` : t`unsichtbar`}
                      />
                    ) : undefined,
                    menu: (
                      <InternalFileMenu
                        file={item}
                        canManage={canUploadInternalFiles}
                        canComment={showComments}
                        addComment={addComment.current?.open}
                        isClosed={certificateCode.status.approval === 'closed'}
                      />
                    )
                  }
                : undefined,
            [isSupplier, certificateCode, showComments, canUploadInternalFiles]
          )}
        </DataTable>
      </div>
      {setCertificateCodeApproval.actionAllowed && (
        <div className="d-flex flex-column px-2 py-2 bg-white border-top">
          <Button
            size="lg"
            onClick={certificateCode.status?.approval === 'closed' ? openCertificate : closeCertificate}
          >
            {certificateCode.status?.approval === 'closed' ? t`Zeugniscode öffnen` : t`Zeugniscode schließen`}
          </Button>
        </div>
      )}

      <AddCommentModal ref={addComment} />
    </>
  )
}

export default CertificateFilesTable
