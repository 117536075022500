import classNames from 'classnames'
import React, { useMemo } from 'react'
import { NavLink } from 'react-router-dom'
import { HeatingStatus, SteelCertificateStatus } from '../../types/StatusTypes'
import Checkbox from '../Checkbox'
import { Tooltip } from '../Overlays/Tooltip'
import { StatusIcon } from '../StatusIcon'
import { t } from '@lingui/macro'
import Check from 'jsx:./../../assets/icons/check.svg'
import Clock from 'jsx:./../../assets/icons/clock.svg'

export type ListItemProps = {
  tooltip?: string | React.ReactNode
  active?: boolean
  onClick?: React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>['onClick']
  onDoubleClick?: React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  >['onDoubleClick']
  gray?: boolean
  heatingStatus?: HeatingStatus
  steelcertificateStatus?: SteelCertificateStatus
  url?: string
  // Setting this to true, false OR undefined will show a checkbox in checked, unchecked or indeterminate state
  checked?: boolean
  setChecked?: (nextValue: boolean) => unknown
  children?: React.ReactNode
  productArticle?: boolean
}

export const ListItemSteelworks: React.FC<ListItemProps> = ({
  tooltip,
  active = false,
  onClick,
  onDoubleClick,
  gray = false,
  steelcertificateStatus,
  heatingStatus,
  children,
  url,
  ...props
}) => {
  const statusIcons = useMemo(() => {
    return (
      <div className="listview-item-status">
        {heatingStatus && heatingStatus.approval === 'pending' && (
          <StatusIcon variant="danger" size="sm" title={t`Freigabe ausstehend`} icon={Clock} />
        )}
        {heatingStatus && heatingStatus.approval === 'approved' && (
          <StatusIcon variant="warning" size="sm" title={t`Freigegeben, PDF wird generiert`} icon={Check} />
        )}
        {heatingStatus && heatingStatus.approval === 'completed' && (
          <StatusIcon variant="success" size="sm" title={t`Freigegeben`} icon={Check} />
        )}
        {steelcertificateStatus && steelcertificateStatus.approval === 'pending' && (
          <StatusIcon variant="danger" size="sm" title={t`Freigabe ausstehend`} icon={Clock} />
        )}
        {steelcertificateStatus && steelcertificateStatus.approval === 'approved' && (
          <StatusIcon variant="warning" size="sm" title={t`Freigegeben, PDF wird generiert`} icon={Check} />
        )}
        {steelcertificateStatus && steelcertificateStatus.approval === 'completed' && (
          <StatusIcon variant="success" size="sm" title={t`Freigegeben`} icon={Check} />
        )}
      </div>
    )
  }, [heatingStatus, steelcertificateStatus])

  const content =
    'checked' in props ? (
      <Checkbox allowIndeterminateState value={props.checked} onChange={props.setChecked} className="label-disabled">
        {children}
      </Checkbox>
    ) : (
      children
    )

  const listItem = url ? (
    <NavLink to={url} activeClassName="active" className={classNames('listview-item', gray && 'gray')}>
      <div className="listview-item-label">
        <Tooltip text={tooltip} placement="bottom-start" delay={{ show: 1000, hide: 0 }}>
          {content}
        </Tooltip>
      </div>
      {statusIcons}
    </NavLink>
  ) : (
    <button
      className={classNames('listview-item', active && 'active', gray && 'gray')}
      onClick={onClick}
      onDoubleClick={onDoubleClick}
      disabled={!onClick}
    >
      <div className="listview-item-label">{content}</div>
      {statusIcons}
    </button>
  )

  return <div>{listItem}</div>
}
