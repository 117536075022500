import { t } from '@lingui/macro'
import { useEffect, useMemo } from 'react'

import { Alert, Button, Col, Form, FormControl, Row } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { passwordRepeat, passwordSecurity } from '../../components/Form/validators'
import { FlexContainer } from '../../components/ListView/FlexContainer'
import { Loader } from '../../components/Loader'
import LocalizedLink from '../../components/LocalizedLink'
import { useSettings } from '../../data/remote/Settings'
import { ConfirmEmailInput, useAuthentication, useConfirmEmail } from '../../data/remote/User'
import { createRoute } from '../../navigation/createRoute'
import { APIError } from '../../scripts/useTrackedPromise'
import LoginButton from '../Login/LoginButton'

const FinishRegistration: React.FC<{
  submit: (args: ConfirmEmailInput) => void
  running: boolean
  error?: APIError
  confirmationHash: string
}> = ({ submit, confirmationHash, running, error }) => {
  const { register, handleSubmit, formState, getValues } = useForm<{
    username: string
    password: string
    passwordRepeat: string
  }>()

  const onSubmit = useMemo(
    () =>
      handleSubmit((input) => {
        submit({
          ...input,
          hash: confirmationHash
        })
      }),
    [confirmationHash, handleSubmit, submit]
  )

  return (
    <Form onSubmit={onSubmit}>
      <Form.Group controlId="username">
        <Form.Label sronly={'true'}>{t`Benutzername`}</Form.Label>
        <Form.Control
          type="text"
          placeholder={t`Benutzername`}
          size="lg"
          className="form-control-login"
          isInvalid={!!formState.errors.username}
          autoComplete="off"
          autoCapitalize="off"
          {...register('username', {
            required: t`Bitte geben Sie einen Benutzernamen ein.`
          })}
        />
        {formState.errors.username && (
          <FormControl.Feedback type="invalid">{formState.errors.username.message}</FormControl.Feedback>
        )}
      </Form.Group>
      <Form.Group controlId="password">
        <Form.Label sronly={'true'}>{t`Passwort`}</Form.Label>
        <Form.Control
          type="password"
          placeholder={t`Passwort`}
          size="lg"
          className="form-control-login"
          isInvalid={!!formState.errors.password}
          {...register('password', { validate: { passwordSecurity } })}
        />
        {formState.errors.password && (
          <FormControl.Feedback type="invalid">{formState.errors.password.message}</FormControl.Feedback>
        )}
      </Form.Group>
      <Form.Group controlId="passwordRepeat">
        <Form.Label sronly={'true'}>{t`Passwort wiederholen`}</Form.Label>
        <Form.Control
          type="password"
          placeholder={t`Passwort wiederholen`}
          size="lg"
          className="form-control-login"
          isInvalid={!!formState.errors.passwordRepeat}
          {...register('passwordRepeat', {
            validate: passwordRepeat(() => getValues().password)
          })}
        />
        {formState.errors.passwordRepeat && (
          <FormControl.Feedback type="invalid">{formState.errors.passwordRepeat.message}</FormControl.Feedback>
        )}
      </Form.Group>
      {error && (
        <Alert variant="danger">
          <Alert.Heading>{t`Fehler bei der Registrierung`}</Alert.Heading>
          {error?.response?.data?.message}
        </Alert>
      )}
      <Row>
        <Col md={12}>
          <Button variant="primary2" type="submit" size="lg" className="mt-3 w-100" disabled={running}>
            {t`Registrierung abschließen`}
          </Button>
        </Col>
      </Row>
    </Form>
  )
}

const ConfirmEmail = createRoute('ConfirmEmail', function ConfirmEmail({ params: { userId, confirmationHash } }) {
  const { loggedIn } = useAuthentication()
  const { settings } = useSettings()

  const { run: confirmEmail, running, actionAllowed, result, error } = useConfirmEmail(userId)
  useEffect(() => {
    if (actionAllowed) {
      confirmEmail({ hash: confirmationHash })
    }
  }, [actionAllowed, confirmEmail, confirmationHash])

  if (!actionAllowed) {
    return (
      <FlexContainer bg="light" padding>
        <div className="text-content px-1">
          <h1 className="h2">{t`Sie können die Email-Adresse anderer Nutzer nicht bestätigen`}</h1>
          <div className="container-box">
            <div className="content-elements">
              <p>{t`Dieser Bestätigungslink gehört zu einem anderen Nutzer. Sie müssen sich erst abmelden, um diesen Link zu bestätigen.`}</p>
              <LocalizedLink to={settings?.pages.logout} component={Button}>{t`abmelden`}</LocalizedLink>
            </div>
          </div>
        </div>
      </FlexContainer>
    )
  }

  if (loggedIn) {
  }

  let content = (
    <>
      <h1>{t`Account bestätigen`}</h1>
      <Loader width={200} />
    </>
  )

  if (result?.data.data.completed === false) {
    content = (
      <>
        <h1>{t`Registrierung abschließen`}</h1>
        <FinishRegistration submit={confirmEmail} running={running} error={error} confirmationHash={confirmationHash} />
      </>
    )
  } else if (error) {
    content = (
      <>
        <h1>{t`Account bestätigen`}</h1>
        <Alert variant="danger">
          <Alert.Heading>{t`Beim Aktivieren Ihres Accounts ist ein Problem aufgetreten.`}</Alert.Heading>
          {error?.response?.data?.message ?? t`Bitte versuchen Sie es später erneut.`}
        </Alert>
        <LocalizedLink
          componentIsLink
          component={loggedIn ? Button : LoginButton}
          to={loggedIn ? settings?.pages.profile : '/'}
        >
          {loggedIn ? 'Zu Ihrem Profil' : t`Zur Anmeldung`}
        </LocalizedLink>
      </>
    )
  } else if (!running && result) {
    content = (
      <>
        <h1>{t`Account bestätigen`}</h1>
        <Alert variant="success">
          <Alert.Heading className="mb-0">{t`Ihr Account/E-Mail wurde erfolgreich bestätigt.`}</Alert.Heading>
        </Alert>
        <LocalizedLink
          componentIsLink
          component={loggedIn ? Button : LoginButton}
          to={loggedIn ? settings?.pages.profile : '/'}
        >
          {loggedIn ? 'Zu Ihrem Profil' : t`Zur Anmeldung`}
        </LocalizedLink>
      </>
    )
  }

  if (loggedIn) {
    return <div className="container-center p-3">{content}</div>
  }

  return <>{content}</>
})

export default ConfirmEmail
