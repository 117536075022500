import React, { useEffect, useCallback } from 'react'
import { OverlayInjectedProps } from 'react-bootstrap/esm/Overlay'
import { useResizeDetector } from 'react-resize-detector'
import { PopoverProps } from 'react-bootstrap'

type ExtendedOverlayInjectedProps = OverlayInjectedProps & {
  popper?: {
    scheduleUpdate?: () => void
  }
}

type RefType = HTMLElement | null

export const makeResizableOverlay = <T extends PopoverProps>(Component: React.ComponentType<T>) =>
  React.forwardRef<HTMLDivElement, T & ExtendedOverlayInjectedProps>(function UpdatingPopover(props, ref) {
    const { width, height, ref: contentRef } = useResizeDetector<HTMLDivElement>()

    useEffect(() => {
      const popover = contentRef.current?.parentElement?.parentElement

      popover?.classList.add('animated-size')
      props.popper?.scheduleUpdate?.()
      const timeout = setTimeout(() => {
        popover?.classList.remove('animated-size')
      }, 200)
      return () => clearTimeout(timeout)
    }, [width, height, props.popper, contentRef])

    const updateArrowClasses = useCallback((node: RefType) => {
      if (node) {
        node.classList.add('popover-arrow')
      }
    }, [])

    const mergeRefs = (node: RefType) => {
      updateArrowClasses(node)
      if (props.arrowProps?.ref) {
        if (typeof props.arrowProps.ref === 'function') {
          props.arrowProps.ref(node)
        } else if (props.arrowProps.ref && 'current' in props.arrowProps.ref) {
          ;(props.arrowProps.ref as React.MutableRefObject<RefType>).current = node
        }
      }
    }

    return (
      <Component
        {...(props as T)}
        ref={ref}
        arrowProps={{
          ...props.arrowProps,
          ref: mergeRefs,
          className: `popover-arrow arrow`
        }}
      >
        <div ref={contentRef} className="popover-body">
          {props.children}
        </div>
      </Component>
    )
  })
