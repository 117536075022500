import { t } from '@lingui/macro'
import { ColumnData, useColumnData } from '../../../../components/ListView/DataColumn'
import { OrderDataMinimal, useOrders } from '../../../../data/remote/Order'
import { SetParamsAction } from '../../../../navigation/createRoute'

export const useOrderData = ({
  setParams,
  generatePath,
  supplierId,
  orderId,
  enabled = true
}: {
  setParams: (
    action: SetParamsAction<'Certificates'> | SetParamsAction<'CertificatesBySupplier'>,
    clearOptionals?: boolean | undefined
  ) => void
  generatePath: (
    action: SetParamsAction<'Certificates'> | SetParamsAction<'CertificatesBySupplier'>,
    clearOptionals?: boolean | undefined
  ) => string
  supplierId: string | number | undefined
  orderId: string | undefined
  enabled?: boolean
}): ColumnData<OrderDataMinimal> =>
  useColumnData<OrderDataMinimal>(
    () => ({
      useItems(search, filter) {
        return useOrders(
          {
            search,
            filter,
            supplierId
          },
          enabled
        )
      },
      isActiveItem(item) {
        return item.identifier === orderId
      },
      shouldHaveActiveItem: enabled && !!orderId,
      onActiveItemMiss() {
        setParams({ supplierId, orderId: undefined, productId: undefined, certificateCode: undefined })
      },
      filterOptions: {
        ['']: t`Alle Bestellungen`,
        pending: t`Mit ungeprüften Zeugnissen`,
        denied: t`Mit abgelehnten Zeugnissen`,
        open: t`Mit offenem Status`
      },
      renderItem: (order) => ({
        id: order.id,
        title: order.identifier,
        url: generatePath({ supplierId, orderId: order.identifier }, true),
        gray: order.status.approval === 'closed',
        approved: order.status.approval === 'approved' || order.status.approval === 'closed',
        denied: order.status.approval === 'denied',
        pending: order.status.pending,
        mandatory: order.status.mandatory
      })
    }),
    [enabled, generatePath, orderId, setParams, supplierId]
  )
