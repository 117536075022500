import { t } from '@lingui/macro'
import React from 'react'
import { ContainerBox } from '../../../components/Layout/ContainerBox'
import { FlexContainer } from '../../../components/ListView/FlexContainer'
import { EditProfileForm } from '../../../components/Profile/EditProfileForm'
import { useUser } from '../../../data/remote/User'
import SelectProductGroups from './SelectProductGroups'

export const EditProfile: React.FC = () => {
  const { user } = useUser()

  if (!user) {
    return null
  }

  return (
    <FlexContainer padding>
      <h1 className="h2">{t`Ihre Stammdaten`}</h1>
      <ContainerBox>
        <EditProfileForm />
      </ContainerBox>

      {!user?.isSupplier && <SelectProductGroups user={user} />}
    </FlexContainer>
  )
}
