import { FlexContainer } from '../../../components/ListView/FlexContainer'

import SupplierRatingTable from '../Suppliers/SupplierRatingTable'
import { Permission, usePermissions } from '../../../data/remote/User'
import { createRoute } from '../../../navigation/createRoute'
import { useSupplierData } from '../../../data/remote/Supplier'
import SupplierComplaints from '../Suppliers/SupplierComplaints'

export const SupplierRating = createRoute('SupplierRating', function SupplierRatingCreateView() {
  const { user, actionAllowed } = usePermissions()
  const supplierId = actionAllowed(Permission.Alle_Reklamationen_verwalten) ? undefined : user?.id
  const { data: supplierData, loading } = useSupplierData(supplierId)

  if (!supplierId && !supplierData) {
    return null
  }

  return (
    <FlexContainer padding>
      {!loading && supplierData && <SupplierRatingTable supplierData={supplierData} />}
      {!loading && supplierData && <SupplierComplaints supplierId={supplierData!.id} />}
    </FlexContainer>
  )
})
