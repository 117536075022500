{
  "name": "vetter-suppliers",
  "version": "1.1.71",
  "private": true,
  "description": "Vetter Suppliers",
  "homepage": "https://supplier.forks.com/",
  "browserslist": "> 0.5%, last 2 versions, not dead",
  "scripts": {
    "start:ddev": "cross-env API_HOST=vetter-supplier.ddev.site parcel src/index.html --no-cache --host client.vetter-supplier.ddev.site --https --dist-dir ./build_ddev --public-url /",
    "start:test": "cross-env API_HOST=test-api.supplier.forks.com parcel src/index.html --no-cache --host local.supplier.forks.com --https --dist-dir ./build_test --public-url /",
    "start:live": "cross-env parcel src/index.html --no-cache --host local.supplier.forks.com --https --dist-dir ./build --public-url /",
    "build:ddev": "rm -rf build_ddev && lingui extract && lingui compile && cross-env API_HOST=vetter-supplier.ddev.site parcel build src/index.html --no-cache --dist-dir ./build_ddev --public-url /",
    "build:test": "rm -rf build_test && lingui extract && lingui compile && cross-env API_HOST=test-api.supplier.forks.com parcel build src/index.html --no-cache --dist-dir ./build_test --public-url /",
    "build:live": "rm -rf build && lingui extract && lingui compile && parcel build src/index.html --no-cache --dist-dir ./build --public-url /",
    "lint": "tsc --noEmit --project tsconfig.json && eslint .",
    "lingui:extract": "lingui extract",
    "lingui:compile": "lingui compile",
    "postinstall": "npx patch-package"
  },
  "dependencies": {
    "@babel/runtime": "^7.25.0",
    "@lingui/core": "^3.17.2",
    "@lingui/react": "^3.17.2",
    "@parcel/transformer-svg-react": "^2.8.3",
    "@popperjs/core": "^2.11.8",
    "@reduxjs/toolkit": "^1.9.7",
    "@rehooks/document-title": "^1.0.2",
    "@types/reach__router": "^1.3.15",
    "axios": "^0.21.1",
    "babel-plugin-macros": "3",
    "babel-runtime": "^6.26.0",
    "bootstrap": "^4.6.2",
    "browserslist": "^4.23.3",
    "chart.js": "^4.4.4",
    "core-js": "^3.38.0",
    "cypress": "^12.17.4",
    "dayjs": "^1.11.12",
    "framer-motion": "^9.1.7",
    "html-react-parser": "^5.1.18",
    "jquery": "^3.7.1",
    "lodash": "^4.17.21",
    "path-type": "^5.0.0",
    "popper.js": "^1.16.1",
    "qs": "^6.13.0",
    "react": "^18.3.1",
    "react-bootstrap": "^2.10.5",
    "react-bootstrap-typeahead": "^6.3.2",
    "react-chartjs-2": "^5.2.0",
    "react-contexify": "^6.0.0",
    "react-custom-scrollbars-2": "^4.5.0",
    "react-date-range": "^2.0.1",
    "react-dom": "^18.3.1",
    "react-hook-form": "^7.52.2",
    "react-laag": "^2.0.5",
    "react-loading-skeleton": "^3.4.0",
    "react-popper": "^2.3.0",
    "react-redux": "^8.1.3",
    "react-resize-detector": "^8.1.0",
    "react-router-dom": "^5.3.4",
    "react-toastify": "^9.1.3",
    "react-transition-group": "^4.4.5",
    "redux": "^4.2.1",
    "redux-thunk": "^2.4.2",
    "regenerator-runtime": "^0.14.1",
    "resize-observer-polyfill": "^1.5.1",
    "styled-components": "^5.3.11",
    "swr": "^1.3.0"
  },
  "devDependencies": {
    "@babel/core": "^7.25.2",
    "@babel/preset-env": "^7.25.3",
    "@babel/preset-react": "^7.24.7",
    "@babel/preset-typescript": "^7.24.7",
    "@lingui/babel-preset-react": "^2.9.2",
    "@lingui/cli": "^3.17.2",
    "@lingui/macro": "^3.17.2",
    "@parcel/transformer-sass": "^2.12.0",
    "@parcel/transformer-typescript-tsc": "^2.8.3",
    "@types/lodash": "^4.17.7",
    "@types/node": "^18.19.45",
    "@types/qs": "^6.9.15",
    "@types/react": "^18.3.3",
    "@types/react-bootstrap-typeahead": "^5.1.14",
    "@types/react-date-range": "^1.4.9",
    "@types/react-dom": "^18.3.0",
    "@types/react-redux": "^7.1.33",
    "@types/react-router-dom": "^5.3.3",
    "@typescript-eslint/eslint-plugin": "^5.62.0",
    "@typescript-eslint/parser": "^5.62.0",
    "assert": "^2.1.0",
    "autoprefixer": "^10.4.20",
    "babel-plugin-lodash": "^3.3.4",
    "babel-plugin-react-html-attrs": "^3.0.5",
    "console-browserify": "^1.2.0",
    "cross-env": "^7.0.3",
    "crypto-browserify": "^3.12.0",
    "escape-string-regexp": "^5.0.0",
    "eslint": "^8.57.0",
    "eslint-config-lingui": "^0.5.0",
    "eslint-config-prettier": "^8.10.0",
    "eslint-plugin-deprecation": "^1.6.0",
    "eslint-plugin-import": "^2.29.1",
    "eslint-plugin-prettier": "^4.2.1",
    "eslint-plugin-react": "^7.35.0",
    "eslint-plugin-react-hooks": "^4.6.2",
    "events": "^3.3.0",
    "os-browserify": "^0.3.0",
    "parcel": "^2.12.0",
    "parcel-reporter-static-files-copy": "^1.5.3",
    "path-browserify": "^1.0.1",
    "postcss": "^8.4.41",
    "prettier": "^2.8.8",
    "process": "^0.11.10",
    "querystring-es3": "^0.2.1",
    "react-is": "^18.3.1",
    "sass": "1.77.6",
    "stream-browserify": "^3.0.0",
    "ts-node": "^10.9.2",
    "typescript": "^4.9.5",
    "url": "^0.11.4",
    "util": "^0.12.5",
    "vm-browserify": "^1.1.2",
    "yarn": "^1.22.22"
  },
  "staticFiles": {
    "staticPath": "public",
    "watcherGlob": "**"
  },
  "overrides": {
    "babel-plugin-lodash": {
      "@babel/types": "~7.20.0"
    }
  }
}
