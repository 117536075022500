import React, { useState } from 'react'
import { truncate } from 'lodash'
import { ContainerBox } from '../../../components/Layout/ContainerBox'
import { useSupplierComplaints } from '../../../data/remote/Complaint'
import { Loading } from '../Loading'
import { t } from '@lingui/macro'
import ArrowRight from 'jsx:./../../../assets/icons/chevron-right.svg'
import { useLocalizedPath } from '../../../data/remote/Language'
import { Icon } from '../../../components/Icon'
import { Button } from 'react-bootstrap'
import { StatusDisplay } from '../../../components/StatusDisplay'

type SupplierComplaintsProps = {
  supplierId: number
}

const SupplierComplaints: React.FC<SupplierComplaintsProps> = ({ supplierId }) => {
  const { data: complaints, loading } = useSupplierComplaints({ supplierId })
  const localizedPath = useLocalizedPath()
  const [visibleComplaints, setVisibleComplaints] = useState(5)

  const loadMore = () => {
    setVisibleComplaints((prevVisible) => prevVisible + 5)
  }

  const displayedComplaints = complaints?.slice(0, visibleComplaints)

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        complaints &&
        complaints.length > 0 && (
          <ContainerBox>
            <div className="data-table-wrap">
              <h2>{t`Reklamationen`}</h2>
              <table className="data-table mt-3">
                <thead>
                  <tr>
                    <th className="col-1">
                      <div>#</div>
                    </th>
                    <th className="col-1">
                      <div>{t`Datum`}</div>
                    </th>
                    <th className="col-2">
                      <div>{t`CAQ`}</div>
                    </th>
                    <th className="col">
                      <div>{t`Kurzbeschreibung`}</div>
                    </th>
                    <th className="col-1">
                      <div>{t`Status`}</div>
                    </th>
                    <th className="col-1">
                      <div></div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {displayedComplaints &&
                    displayedComplaints.map((complaint, index) => (
                      <tr key={complaint.id}>
                        <td className="col-1">{index + 1}</td>
                        <td className="col-1">{new Date(complaint.crdate).toLocaleDateString()}</td>
                        <td className="col-2">{complaint.caq}</td>
                        <td className="col-4">{truncate(complaint.description, { length: 100 })}</td>
                        <td className="col-2">
                          <StatusDisplay status={complaint.status} />
                        </td>
                        <td className="col-1">
                          <Button
                            size="sm"
                            className="ml-auto"
                            onClick={() => {
                              window.location.href =
                                localizedPath(`/reklamationen`) +
                                `/${complaint.orderIdentifier}/${complaint.product}/${complaint.id}`
                            }}
                          >
                            <Icon svg={ArrowRight} />
                          </Button>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
              {complaints.length > visibleComplaints && (
                <div className="text-center mt-3">
                  <Button onClick={loadMore}>{t`Mehr anzeigen`}</Button>
                </div>
              )}
            </div>
          </ContainerBox>
        )
      )}
    </>
  )
}

export default SupplierComplaints
