import { t } from '@lingui/macro'
import { useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { useSWRConfig } from 'swr'

import { useConfirm } from '../../navigation/Notifications'
import { TrackedAxiosRequest, useTrackedAxiosRequest } from '../../scripts/useTrackedPromise'
import { prefixApiParams } from '../../scripts/utils'
import { useRefreshSWR, useSWR } from '../local/hooks/swrHooks'
import { useCurrentLocale, useLocalizedRedirect } from './Language'
import { useRoutes } from './RouteData'
import { ApprovalStatus } from './Status'

export type UserData = {
  id: number
  language: number
  username: string
  lastlogin: Date
  company: string
  firstName: string
  lastName: string
  address: string
  zip: string
  city: string
  country: string
  telephone: string
  email: string
  tempEmail?: string
  altFirstName?: string
  altLastName?: string
  altEmail?: string
  altTelephone?: string
  isSupplier?: boolean
  supplierCode?: string
  supplierNumber?: string
  productGroups?: number[]
  createdBy?: number
  editedBy?: number
  status?: {
    certificates?: {
      approval: ApprovalStatus
      pending: boolean
    }
    regulations?: {
      approval: ApprovalStatus
      pending: boolean
    }
  }
}

export type UserDataInput = Pick<
  UserData,
  | 'isSupplier'
  | 'company'
  | 'supplierCode'
  | 'supplierNumber'
  | 'username'
  | 'address'
  | 'zip'
  | 'city'
  | 'country'
  | 'lastName'
  | 'firstName'
  | 'email'
  | 'telephone'
  | 'altLastName'
  | 'altFirstName'
  | 'altEmail'
  | 'altTelephone'
  | 'language'
> & {
  password?: string
  passwordRepeat?: string
}

/**
 * for permission checking:
 *
 * const { actionAllowed } = useUser()
 * actionAllowed(Permission.Zeugniscodes_anlegen) ? true : false
 */
export const Permission = {
  Portal_nutzen: 1,
  Eigenes_Passwort_zuruecksetzen: 6,
  Eigenes_Profil_bearbeiten: 7,
  Eigene_Zeugnisse_verwalten: 16,
  Zeugniscodes_anlegen: 20,
  Zeugnisse_hochladen: 21,
  Alle_Reklamationen_verwalten: 30,
  Eigene_Reklamationen_verwalten: 31,
  Alle_Zeugnisse_verwalten: 11,
  Alle_Stahlwerks_Zeugnisse_verwalten: 34,
  WEK_Protokolle_abfragen: 35,
  Zeugnisse_pruefen: 22,
  Interne_Dateien_verwalten: 18,
  Kommentare_schreiben: 19,
  Such_Ansicht_nutzen: 13,
  Alle_Lieferantenbewertungen_ansehen: 37,
  Eigene_Dateien_verwalten: 17,
  Alle_Dateien_verwalten: 14,
  Alle_Dateien_pruefen: 15,

  Alle_Lieferanten_verwalten: 10,

  Hilfebereich_ansehen: 8,
  Informations_Seiten_ansehen: 9
} as const

export type Permission = (typeof Permission)[keyof typeof Permission]

export const useAuthentication = (): {
  loggingIn: boolean
  loggedIn: boolean
  login: (username: string, password: string) => void
  logout: () => void
  user: UserData | undefined
  groups: Permission[] | undefined
} => {
  const { data, error } = useSWR<{ user: UserData; groups: Permission[]; requestToken: string }>('/de/user')
  const refresh = useRefreshSWR()
  const routes = useRoutes()

  const user = data?.user
  const groups = data?.groups
  const loggedIn = !!user && !!groups && !!routes.routes.account.length
  const locale = useCurrentLocale()
  const confirm = useConfirm()
  const redirect = useLocalizedRedirect()
  const login = useTrackedAxiosRequest<[username: string, password: string], { user: UserData }>(
    () => ({
      createRequestData: (username: string, password: string) => [
        '/user',
        {
          user: username,
          pass: password,
          logintype: 'login',
          __RequestToken: data?.requestToken
        }
      ],
      thenFn: ({
        data: {
          data: { user }
        }
      }) => {
        // login successful
        // refresh window
        refresh().then(() => {
          if (
            user.isSupplier &&
            user.status?.regulations?.approval !== 'approved' &&
            !user.status?.regulations?.pending
          ) {
            confirm({
              title: t`Ausstehende Bestätigungen`,
              text: t`Es gibt noch Dateien, die Sie bestätigen müssen.`,
              options: [
                {
                  label: t`OK`,
                  type: 'primary',
                  onClick: () => {
                    redirect('/de/dateien') // TODO: Geht nicht, da zu diesem Zeitpunkt evtl. noch keine aktualisierten Routes verfügbar sind?
                  }
                }
              ]
            })
          }
        })
      },
      catchFn: () => {
        refresh()
      },
      messages: {
        success: {
          type: 'success',
          title: t`Login erfolgreich`,
          autoClose: 3000
        },
        error: {
          type: 'error',
          title: t`Login fehlgeschlagen`
        }
      }
    }),
    [confirm, data?.requestToken, redirect, refresh]
  )

  const history = useHistory()
  const { mutate } = useSWRConfig()

  const logout = useTrackedAxiosRequest(
    () => ({
      createRequestData: () => [
        '/user',
        {
          logintype: 'logout'
        }
      ],
      thenFn: async () => {
        mutate(() => true, undefined, false)
        localStorage.clear()
        await refresh()
        history.replace('/' + locale)
      },
      catchFn: () => {
        refresh()
      },
      messages: {
        error: {
          type: 'error',
          title: t`Logout fehlgeschlagen`
        }
      }
    }),
    [mutate, refresh, history, locale]
  )

  return {
    loggedIn: loggedIn || (!data && !error),
    loggingIn: login.running || logout.running,
    login: login.run,
    logout: logout.run,
    user,
    groups
  }
}

export const usePermission = (key: keyof typeof Permission | 'supplier' | 'employee'): boolean => {
  const { user, groups } = useAuthentication()

  if (key === 'supplier') {
    return !!(user && user?.isSupplier)
  }
  if (key === 'employee') {
    return !!(user && !user?.isSupplier)
  }
  return groups?.includes(Permission[key]) ?? false
}

export const usePermissions = (): {
  loggedIn: boolean
  user: UserData | undefined
  groups: Permission[] | undefined
  actionAllowed: (group: Permission | 'supplier' | 'employee') => boolean
} => {
  const { data } = useSWR<{ user: UserData; groups: Permission[] }>('/de/user')

  const { user, groups } = data ?? {}
  const loggedIn = !!user && !!groups

  const actionAllowed = useCallback(
    (group: Permission | 'supplier' | 'employee'): boolean => {
      return !!(
        (group === 'supplier' && user?.isSupplier) ||
        (group === 'employee' && !user?.isSupplier) ||
        (groups && groups.includes(group as Permission))
      )
    },
    [user, groups]
  )

  return {
    loggedIn,
    user,
    groups,
    actionAllowed
  }
}

export const useRequestPasswordResetEmail = (): TrackedAxiosRequest<[{ email: string }]> =>
  useTrackedAxiosRequest(
    () => ({
      createRequestData: (params) => ['/resetPassword', prefixApiParams(params)]
    }),
    []
  )

export const useResetPassword = (
  userId: number
): TrackedAxiosRequest<[{ hash: string; password: string; passwordRepeat: string }]> =>
  useTrackedAxiosRequest(
    () => ({
      createRequestData: (params) => ['/resetPassword/' + userId, prefixApiParams(params), { method: 'PATCH' }]
    }),
    [userId]
  )

export const useUser = (): {
  loading: boolean
  user: UserData | undefined
  groups: Permission[] | undefined
  //reload: SWRResponse<UserData, Error>['mutate']
  updateData: (newData: UserDataInput) => void
  updateDataLoading: boolean
} => {
  const { data, error } = useSWR<{ user: UserData; groups: Permission[] }>('/user')
  const loading = !data && !error
  const { user, groups } = data ?? {}
  const refresh = useRefreshSWR()

  const updateData = useTrackedAxiosRequest<[newData: UserDataInput], { user: UserData; groups: number[] }>(
    () => ({
      createRequestData: (newData) => {
        return ['/user', prefixApiParams({ ...newData }), { method: 'PATCH' }]
      },
      thenFn: () => {
        refresh()
      },
      messages: {
        success: {
          title: t`Speichern erfolgreich`
        },
        error: {
          title: t`Speichern fehlgeschlagen`
        }
      }
    }),
    [refresh]
  )

  return {
    loading,
    user: user,
    groups: groups,
    updateData: updateData.run,
    updateDataLoading: updateData.running
  }
}

export const useCanConfirmEmail = (userId: number | string | undefined): boolean => {
  const { loggedIn, user } = useAuthentication()
  return !loggedIn || user?.id === Number(userId)
}
export type ConfirmEmailInput =
  | {
      hash: string
      username: string
      password: string
      passwordRepeat: string
    }
  | {
      hash: string
    }
export type ConfirmEmailResult = { completed: boolean }
export const useConfirmEmail = (
  userId: number | string | undefined
): TrackedAxiosRequest<[args: ConfirmEmailInput], ConfirmEmailResult> => {
  const actionAllowed = useCanConfirmEmail(userId)
  return useTrackedAxiosRequest<[args: ConfirmEmailInput], ConfirmEmailResult>(
    () => ({
      createRequestData(args) {
        return ['/confirmEmail/' + Number(userId), prefixApiParams(args), { method: 'PATCH' }]
      },
      actionAllowed
    }),
    [userId, actionAllowed]
  )
}
