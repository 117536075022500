import { t } from '@lingui/macro'
import dayjs from 'dayjs'
import TableCommentsIcon from '../../../../components/DataTable/TableCommentsIcon'
import TableStatusIcon from '../../../../components/DataTable/TableStatusIcon'
import { InternalFileData } from '../../../../data/remote/InternalFile'

import { AddCommentModalRef } from '../../Certificates/AddCommentModal'
import InternalFileMenu from '../../Files/InternalFilesMenu'

type generateInternalFilesTableRowProps = {
  addComment: React.RefObject<AddCommentModalRef>
  canManage: boolean
  fileData: InternalFileData
  complaintId: number
  onVisibilityChange?: (internalFile: InternalFileData, visibile: boolean) => void
  isClosed: boolean
}

const generateInternalFilesTableRow = ({
  addComment,
  canManage,
  fileData,
  onVisibilityChange,
  isClosed
}: generateInternalFilesTableRowProps) => ({
  name: (
    <>
      {fileData?.file && (
        <>
          <a href={fileData.file.publicUrl} target="_blank" rel="noreferrer">
            <u>{fileData.file.properties.title}</u>
          </a>
        </>
      )}
    </>
  ),
  date: fileData?.tstamp ? dayjs(fileData.tstamp).format('L') : '',
  menu: <InternalFileMenu file={fileData} canManage={canManage} isClosed={isClosed} />,
  comments: fileData?.id && (
    <TableCommentsIcon
      onOpen={addComment.current?.open}
      forType="internalfiles"
      forId={fileData?.id}
      count={fileData?.comments ? fileData?.comments : 0}
    />
  ),
  status: null,
  visible: fileData?.id && (
    <TableStatusIcon
      onClick={() => {
        if (fileData) {
          onVisibilityChange?.(fileData, !fileData.public)
        }
      }}
      className="cursor-pointer"
      status={fileData?.public ? 'visible' : 'hidden'}
      tooltip={fileData?.public ? t`sichtbar` : t`unsichtbar`}
    />
  )
})

export default generateInternalFilesTableRow
