import React, { useMemo } from 'react'
import Button from 'react-bootstrap/Button'
import FormControl from 'react-bootstrap/FormControl'
import Form from 'react-bootstrap/Form'
import { useForm } from 'react-hook-form'
import SquareRight from 'jsx:./../../assets/icons/square-right.svg'
import { Icon } from '../../components/Icon'
import { Row, Col } from 'react-bootstrap'
import { t } from '@lingui/macro'
import { Link } from 'react-router-dom'
import { useSettings } from '../../data/remote/Settings'
import { useAuthentication } from '../../data/remote/User'
import { useRouteById } from '../../data/remote/RouteData'
import { createRoute } from '../../navigation/createRoute'
import { LocalizedRedirect } from '../../components/LocalizedRedirect'

type LoginInput = {
  username: string
  password: string
}

const LoginForm: React.FC = () => {
  const { settings } = useSettings()
  const { login, loggingIn, loggedIn } = useAuthentication()
  const { register, handleSubmit, formState } = useForm<LoginInput>()

  const onSubmit = useMemo(
    () => handleSubmit((input: LoginInput) => login(input.username, input.password)),
    [handleSubmit, login]
  )

  const registrationEmail = settings?.registration.email
  const forgotPasswordRoute = useRouteById(settings?.pages.forgotPassword)
  const forgotPasswordLink = settings?.pages?.forgotPassword ? forgotPasswordRoute?.href : undefined

  // if current path contains logout, redirect to main page

  if (!loggedIn && window.location.pathname.includes('logout')) {
    return <LocalizedRedirect to="/" />
  }

  return (
    <>
      <h1>{t`Willkommen`}</h1>
      <Form onSubmit={onSubmit} className="mt-3" noValidate>
        <Form.Group controlId="login-username" className="form-group">
          <Form.Label sronly={'true'}>{t`Benutzername`}</Form.Label>
          <Form.Control
            type="text"
            placeholder={t`Benutzername`}
            size="lg"
            className="form-control-login"
            isInvalid={!!formState.errors.username}
            autoComplete="off"
            autoCapitalize="off"
            {...register('username', {
              required: t`Bitte geben Sie Ihren Benutzernamen ein.`
            })}
          />
          {formState.errors.username && (
            <FormControl.Feedback type="invalid">{formState.errors.username.message}</FormControl.Feedback>
          )}
        </Form.Group>

        <Form.Group controlId="login-password" className="form-group">
          <Form.Label sronly={'true'}>{t`Passwort`}</Form.Label>
          <Form.Control
            type="password"
            placeholder={t`Passwort`}
            size="lg"
            className="form-control-login"
            isInvalid={!!formState.errors.password}
            {...register('password', { required: t`Bitte geben Sie Ihr Passwort ein.` })}
          />
          {formState.errors.password && (
            <FormControl.Feedback type="invalid">{formState.errors.password.message}</FormControl.Feedback>
          )}
        </Form.Group>
        <Row>
          <Col md={6}>
            <Button variant="primary2" type="submit" size="lg" className="mt-3 w-100" disabled={loggingIn}>
              {t`Log-In`}
            </Button>
          </Col>
        </Row>
      </Form>
      <Row className="mt-3">
        {forgotPasswordLink && (
          <Col>
            <Link to={forgotPasswordLink} className="large">
              {t`Passwort vergessen`}
            </Link>
          </Col>
        )}
        {registrationEmail && (
          <Col lg={6} className="text-lg-right mt-1 mt-lg-0 ml-auto">
            <a href={'mailto:' + registrationEmail} className="large">
              {t`Zugang anfordern`}
              <Icon svg={SquareRight} className="icon-right" />
            </a>
          </Col>
        )}
      </Row>
    </>
  )
}

export const Login = createRoute('Login', ({ navigatorType }) => {
  const { loggedIn } = useAuthentication()
  const currentPath = window.location.pathname

  // Handle logout path specifically
  if (!loggedIn && currentPath.includes('logout')) {
    return <LocalizedRedirect to="/" />
  }

  if (!navigatorType) {
    return <LoginForm />
  }

  // If user is on main path and logged in, show main content
  if (navigatorType === 'main' && loggedIn) {
    return <LocalizedRedirect to="/zeugnisse" />
  }

  // If user is not logged in, show login form regardless of navigator type
  if (!loggedIn) {
    return <LoginForm />
  }

  // Default case - redirect to localized home
  return <LocalizedRedirect to="/" />
})
