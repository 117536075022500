import { t } from '@lingui/macro'
import axios from 'axios'
import { useCallback, useEffect, useRef } from 'react'
import { useConfirm } from '../../navigation/Notifications'
import { StartJobFN } from '../../scripts/JobQueue'
import { showToast } from '../../scripts/toast'
import { useTrackedAxiosRequest } from '../../scripts/useTrackedPromise'
import { prefixApiParams } from '../../scripts/utils'
import { UploadFileLocation, UploadFileRequest } from '../../types/FileDataTypes'
import { useRefreshSWR } from '../local/hooks/swrHooks'
import { enqeueUpload, removeUploads } from '../local/modules/uploads'
import { useCertificateCode } from './CertificateCode'
import { useCurrentLocale, useLocalizedPath } from './Language'
import { usePermission } from './User'
import { useSettings } from './Settings'
import { generatePath, useHistory } from 'react-router-dom'
import { ModalRef } from '../../components/Overlays/Modal'
import { FileUploadModal } from '../../components/FileUploadModal'
import { createRoot, Root } from 'react-dom/client'

const useUploadFile = (): ((data: UploadFileRequest) => StartJobFN) => {
  const refresh = useRefreshSWR()
  const locale = useCurrentLocale()
  const history = useHistory()
  const localizedPath = useLocalizedPath()
  return useCallback(
    (data) => (onProgress) => {
      const cancelTokenSource = axios.CancelToken.source()
      const url =
        data.type === 'certificate'
          ? `/${locale}/certificatecodes/${data.certificateCodeId}/certificates`
          : data.type === 'internalfile' && data.certificateCodeId
          ? `/${locale}/certificatecodes/${data.certificateCodeId}/internalfiles`
          : data.type === 'regulationfile'
          ? `/${locale}/regulationfolders/${data.folderId}/regulationfiles`
          : data.type === 'internalfile' && data.complaintId
          ? `/${locale}/complaints/${data.complaintId}/internalfiles`
          : data.type === 'specificfile'
          ? `/${locale}/suppliers/${data.supplierId}/specificfiles`
          : data.type === 'complaintfile'
          ? `/${locale}/complaints/${data.complaintId}/complaintfiles`
          : data.type === 'complaintfile_answer'
          ? `/${locale}/complaints/${data.complaintId}/complaintfiles/${data.complaintFileId}`
          : data.type === 'steelheatingfile'
          ? `/${locale}/suppliers/${data.supplierId}/steelheatings`
          : data.type === 'steelcertificatefile'
          ? `/${locale}/steelcertificates/${data.steelcertificatesId}`
          : `/${locale}/suppliers/${data.supplierId}/regulationfiles/${data.regulationFileId}/confirmationfiles`

      const formData = new FormData()

      if (data.type == 'complaintfile_answer') {
        for (const [key, value] of Object.entries(
          prefixApiParams({
            file: data.file
          })
        )) {
          formData.set(key, value as string | Blob)
        }
      } else if (data.type === 'complaintfile') {
        for (const [key, value] of Object.entries(
          prefixApiParams({
            complaintFileType: data.complaintFileType,
            file: data.file
          })
        )) {
          formData.set(key, value as string | Blob)
        }
      } else if (data.type === 'steelheatingfile') {
        for (const [key, value] of Object.entries(
          prefixApiParams({
            file: data.file
          })
        )) {
          formData.set(key, value as string | Blob)
        }
      } else {
        for (const [key, value] of Object.entries(
          prefixApiParams({
            file: data.file
          })
        )) {
          formData.set(key, value as string | Blob)
        }
      }

      if (data.type === 'complaintfile_answer') {
        const promise = axios
          .post(url, formData, {
            cancelToken: cancelTokenSource.token,
            onUploadProgress(e) {
              const progress = e.total ? e.loaded / e.total : 0
              onProgress(progress)
            }
          })
          .then(() => {
            showToast({
              type: 'success',
              title: t`Upload erfolgreich!`,
              text: data.file.name
            })
          })
          .catch((error) => {
            if (!axios.isCancel(error)) {
              showToast({
                type: 'error',
                title: t`Upload fehlgeschlagen`,
                text: error?.response?.data?.message ?? data.file.name
              })
            }
          })
          .finally(() => {
            if (data.complaintFileType === 'steelheaingfile') {
            } else {
              refresh(
                ({ path }) =>
                  path.includes('certificates') ||
                  path.includes('internalfiles') ||
                  path.includes('suppliers') ||
                  path.includes('regulationfiles') ||
                  path.includes('complaintfiles') ||
                  path.includes('confirmationfiles') ||
                  path.includes('steelheatings') ||
                  path.includes('complaints') ||
                  path.includes('steelcertificates')
              )
            }
          })

        return {
          promise,
          cancel: () => cancelTokenSource.cancel('User abort')
        }
      } else if (data.type === 'steelcertificatefile') {
        const promise = axios
          .post(url, formData, {
            cancelToken: cancelTokenSource.token,
            onUploadProgress(e) {
              const progress = e.total ? e.loaded / e.total : 0
              onProgress(progress)
            }
          })
          .then(() => {
            showToast({
              type: 'success',
              title: t`Upload erfolgreich!`,
              text: data.file.name
            })
          })
          .catch((error) => {
            if (!axios.isCancel(error)) {
              showToast({
                type: 'error',
                title: t`Upload fehlgeschlagen`,
                text: error?.response?.data?.message ?? data.file.name
              })
            }
          })
          .finally(() => {
            refresh(
              ({ path }) =>
                path.includes('certificates') ||
                path.includes('internalfiles') ||
                path.includes('suppliers') ||
                path.includes('regulationfiles') ||
                path.includes('complaintfiles') ||
                path.includes('complaints') ||
                path.includes('confirmationfiles')
            )
          })
        return {
          promise,
          cancel: () => cancelTokenSource.cancel('User abort')
        }
      } else if (data.type === 'steelheatingfile') {
        const promise = axios
          .post(url, formData, {
            cancelToken: cancelTokenSource.token,
            onUploadProgress(e) {
              const progress = e.total ? e.loaded / e.total : 0
              onProgress(progress)
            }
          })
          .then((response) => {
            showToast({
              type: 'success',
              title: t`Upload erfolgreich!`,
              text: data.file.name
            })

            const localizedPathString = localizedPath('/de/stahlwerke')
            const path = generatePath(localizedPathString + '/:supplierId/:id/', {
              supplierId: response.data.data.supplier,
              id: response.data.data.id
            })
            history.push(path)
          })
          .catch((error) => {
            console.warn(error)
            if (!axios.isCancel(error)) {
              showToast({
                type: 'error',
                title: t`Upload fehlgeschlagen`,
                text: error?.response?.data?.message ?? data.file.name
              })
            }
          })
          .finally(() => {
            refresh(({ path }) => path.includes('steelheatings') || path.includes('steelcertificates'))
          })
        return {
          promise,
          cancel: () => cancelTokenSource.cancel('User abort')
        }
      } else {
        const promise = axios
          .post(url, formData, {
            cancelToken: cancelTokenSource.token,
            onUploadProgress(e) {
              const progress = e.total ? e.loaded / e.total : 0
              onProgress(progress)
            }
          })
          .then(() => {
            showToast({
              type: 'success',
              title: t`Upload erfolgreich!`,
              text: data.file.name
            })
          })
          .catch((error) => {
            if (!axios.isCancel(error)) {
              showToast({
                type: 'error',
                title: t`Upload fehlgeschlagen`,
                text: error?.response?.data?.message ?? data.file.name
              })
            }
          })
          .finally(() => {
            refresh(
              ({ path }) =>
                path.includes('certificates') ||
                path.includes('internalfiles') ||
                path.includes('suppliers') ||
                path.includes('regulationfiles') ||
                path.includes('complaintfiles') ||
                path.includes('complaints') ||
                path.includes('confirmationfiles')
            )
          })
        return {
          promise,
          cancel: () => cancelTokenSource.cancel('User abort')
        }
      }
    },
    [history, locale, localizedPath, refresh]
  )
}

export const useCanUploadFiles = (request: UploadFileLocation): boolean => {
  const { data: certificateCode } = useCertificateCode(request.certificateCodeId)
  const certificateClosed =
    request.type !== 'specificfile' &&
    request.type !== 'regulationfile' &&
    request.type !== 'confirmationfile' &&
    (!certificateCode || certificateCode.status.approval === 'closed') &&
    request.type === 'complaintfile'

  const isActionAllowed =
    usePermission(
      request.type === 'certificate'
        ? 'Zeugnisse_hochladen'
        : request.type === 'specificfile'
        ? 'Alle_Lieferanten_verwalten'
        : request.type === 'regulationfile'
        ? 'Alle_Dateien_verwalten'
        : request.type === 'confirmationfile'
        ? 'Eigene_Dateien_verwalten'
        : request.type === 'complaintfile'
        ? 'Alle_Reklamationen_verwalten'
        : request.type === 'complaintfile_answer'
        ? 'Eigene_Reklamationen_verwalten'
        : request.type === 'steelheatingfile'
        ? 'Alle_Stahlwerks_Zeugnisse_verwalten'
        : request.type === 'steelcertificatefile'
        ? 'Alle_Stahlwerks_Zeugnisse_verwalten'
        : 'Interne_Dateien_verwalten'
    ) && !certificateClosed
  return isActionAllowed
}

export const useCanUploadComplaintFiles = (request: UploadFileLocation): boolean => {
  const isActionAllowed = usePermission(
    request.type === 'complaintfile'
      ? 'Alle_Reklamationen_verwalten'
      : request.type === 'complaintfile_answer'
      ? 'Eigene_Reklamationen_verwalten'
      : 'Interne_Dateien_verwalten'
  )
  return isActionAllowed
}

export const useUploadFiles = (data: UploadFileLocation): [uploadFiles: () => void, isActionAllowed: boolean] => {
  const uploadFile = useUploadFile()
  const isActionAllowed = useCanUploadFiles(data)
  const { settings } = useSettings()
  const modalRef = useRef<ModalRef>(null)
  const rootRef = useRef<Root | null>(null)

  const getAcceptedTypes = () => {
    switch (data.type) {
      case 'complaintfile':
        return settings?.uploads.complaintFiles.typeExtensions.toString()
      case 'internalfile':
        return settings?.uploads.internalFiles.typeExtensions.toString()
      case 'regulationfile':
        return settings?.uploads.regulationFiles.typeExtensions.toString()
      case 'steelcertificatefile':
        return settings?.uploads.steelCertificates.typeExtensions.toString()
      case 'confirmationfile':
        return settings?.uploads.confirmationFiles.typeExtensions.toString()
      default:
        return settings?.uploads.certificates.typeExtensions.toString()
    }
  }

  const getFileSize = () => {
    switch (data.type) {
      case 'complaintfile':
        return settings?.uploads.complaintFiles.maxSize
      case 'internalfile':
        return settings?.uploads.internalFiles.maxSize
      case 'regulationfile':
        return settings?.uploads.regulationFiles.maxSize
      case 'steelcertificatefile':
        return settings?.uploads.steelCertificates.maxSize
      case 'confirmationfile':
        return settings?.uploads.confirmationFiles.maxSize
      default:
        return settings?.uploads.certificates.maxSize
    }
  }

  const handleUpload = useCallback(
    (files: FileList) => {
      const jobIds: number[] = []
      let done = 0
      const count = files.length

      const onDone = () => {
        done++
        if (done === count) {
          removeUploads(jobIds)
        }
      }

      for (const file of files) {
        jobIds.push(
          enqeueUpload({
            start: uploadFile({
              ...data,
              file
            }),
            description: file.name,
            resolve: onDone,
            reject: onDone
          })
        )
      }
    },
    [data, uploadFile]
  )

  const uploadFiles = useCallback(() => {
    modalRef.current?.open()
  }, [])

  useEffect(() => {
    // Create container for modal
    const modalContainer = document.createElement('div')
    modalContainer.id = 'file-upload-modal'
    document.body.appendChild(modalContainer)

    // Create root and render modal
    rootRef.current = createRoot(modalContainer)
    rootRef.current.render(
      <FileUploadModal
        ref={modalRef}
        title={t`Datei hochladen`}
        onUpload={handleUpload}
        acceptedFileTypes={getAcceptedTypes()}
        maxFileSize={getFileSize()}
      />
    )

    // Cleanup
    return () => {
      if (rootRef.current) {
        rootRef.current.unmount()
      }
      if (modalContainer.parentNode) {
        modalContainer.parentNode.removeChild(modalContainer)
      }
    }
  }, [handleUpload, getAcceptedTypes])

  return [uploadFiles, isActionAllowed]
}

export const useUploadComplaintFile = (
  data: UploadFileLocation
): [uploadFiles: () => void, isActionAllowed: boolean] => {
  const uploadFile = useUploadFile()
  const isActionAllowed = useCanUploadComplaintFiles(data)
  const { settings } = useSettings()
  const uploadFiles = useCallback(() => {
    const input = document.createElement('input')
    input.accept = settings?.uploads.complaintFiles.types.toString() || ''
    input.multiple = true
    input.type = 'file'
    input.style.position = 'absolute'
    input.style.opacity = '0'
    document.body.appendChild(input)
    input.onchange = () => {
      if (input.files) {
        const jobIds: number[] = []
        let done = 0
        const count = input.files.length
        const onDone = () => {
          done++
          if (done === count) {
            removeUploads(jobIds)
          }
        }
        for (const file of input.files) {
          jobIds.push(
            enqeueUpload({
              start: uploadFile({
                ...data,
                file
              }),
              description: file.name,
              resolve: onDone,
              reject: onDone
            })
          )
        }
      }
    }
    window.addEventListener(
      'focus',
      () => {
        document.body.removeChild(input)
      },
      { once: true }
    )
    input.dispatchEvent(new MouseEvent('click'))
  }, [data, settings?.uploads.complaintFiles.types, uploadFile])
  return [uploadFiles, isActionAllowed]
}

export const useUploadSteelHeatingFile = (
  data: UploadFileLocation,
  modalActions: ModalRef<boolean> & {
    ref: React.MutableRefObject<ModalRef<boolean> | null>
  },
  dragAndDrop: boolean,
  file?: FileList | null,
  resetForm?: () => void
): [uploadFiles: () => void, isActionAllowed: boolean] => {
  const uploadFile = useUploadFile()
  const isActionAllowed = useCanUploadFiles(data)
  const { settings } = useSettings()
  const uploadFiles = useCallback(() => {
    const input = document.createElement('input')
    input.accept = settings?.uploads.steelCertificates.types.toString() || ''
    input.multiple = true
    input.type = 'file'
    input.style.position = 'absolute'
    input.style.opacity = '0'
    document.body.appendChild(input)
    input.onchange = () => {
      if (input.files) {
        const jobIds: number[] = []
        let done = 0
        const count = input.files.length
        const onDone = () => {
          done++
          if (done === count) {
            removeUploads(jobIds)
            resetForm && resetForm()
            modalActions.close()
          }
        }
        for (const file of input.files) {
          jobIds.push(
            enqeueUpload({
              start: uploadFile({
                ...data,
                file
              }),
              description: file.name,
              resolve: onDone,
              reject: onDone
            })
          )
        }
      }
    }
    window.addEventListener(
      'focus',
      () => {
        document.body.removeChild(input)
      },
      { once: true }
    )
    if (dragAndDrop) {
      input.files = file || null
      input.dispatchEvent(new Event('change'))
    } else {
      input.dispatchEvent(new MouseEvent('click'))
    }
  }, [data, dragAndDrop, file, modalActions, resetForm, settings?.uploads.steelCertificates.types, uploadFile])

  return [uploadFiles, isActionAllowed]
}

export const useUploadSteelCertificateFile = (
  data: UploadFileLocation
): [uploadFiles: () => void, isActionAllowed: boolean] => {
  const uploadFile = useUploadFile()
  const isActionAllowed = useCanUploadFiles(data)
  const { settings } = useSettings()
  const uploadFiles = useCallback(() => {
    const input = document.createElement('input')
    input.accept = settings?.uploads.steelCertificates.types.toString() || ''
    input.multiple = true
    input.type = 'file'
    input.style.position = 'absolute'
    input.style.opacity = '0'
    document.body.appendChild(input)
    input.onchange = () => {
      if (input.files) {
        const jobIds: number[] = []
        let done = 0
        const count = input.files.length
        const onDone = () => {
          done++
          if (done === count) {
            removeUploads(jobIds)
          }
        }
        for (const file of input.files) {
          jobIds.push(
            enqeueUpload({
              start: uploadFile({
                ...data,
                file
              }),
              description: file.name,
              resolve: onDone,
              reject: onDone
            })
          )
        }
      }
    }
    window.addEventListener(
      'focus',
      () => {
        document.body.removeChild(input)
      },
      { once: true }
    )
    input.dispatchEvent(new MouseEvent('click'))
  }, [data, settings?.uploads.steelCertificates.types, uploadFile])
  return [uploadFiles, isActionAllowed]
}

type ReplaceFileLocation = {
  type: 'regulationfile' | 'specificfile' | 'complaintfile'
  fileId: number
}

export const useCanReplaceFile = (request: ReplaceFileLocation): boolean => {
  const isActionAllowed = usePermission(
    request.type === 'specificfile' ? 'Alle_Lieferanten_verwalten' : 'Alle_Dateien_verwalten'
  )
  return isActionAllowed
}
export const useReplaceFile = (data: ReplaceFileLocation): [replaceFile: () => void, isActionAllowed: boolean] => {
  const isActionAllowed = useCanReplaceFile(data)
  const refresh = useRefreshSWR()
  const locale = useCurrentLocale()
  const { settings } = useSettings()
  const replaceFile = useCallback(() => {
    const input = document.createElement('input')
    input.accept = settings?.uploads.regulationFiles.types.toString() || ''
    input.multiple = false
    input.type = 'file'
    input.style.position = 'absolute'
    input.style.opacity = '0'
    document.body.appendChild(input)
    input.onchange = () => {
      if (input.files) {
        const jobIds: number[] = []
        let done = 0
        const count = input.files.length
        const onDone = () => {
          done++
          if (done === count) {
            removeUploads(jobIds)
          }
        }
        for (const file of input.files) {
          jobIds.push(
            enqeueUpload({
              start: (onProgress) => {
                const cancelTokenSource = axios.CancelToken.source()
                const url =
                  data.type === 'regulationfile'
                    ? `/${locale}/regulationfiles/${data.fileId}`
                    : `/${locale}/specificfiles/${data.fileId}`

                const formData = new FormData()
                for (const [key, value] of Object.entries(
                  prefixApiParams({
                    file
                  })
                )) {
                  formData.set(key, value as string | Blob)
                }

                const promise = axios
                  .post(url, formData, {
                    cancelToken: cancelTokenSource.token,
                    onUploadProgress(e) {
                      const progress = e.total ? e.loaded / e.total : 0
                      onProgress(progress)
                    }
                  })
                  .then(() => {
                    showToast({
                      type: 'success',
                      title: t`Austauschen erfolgreich!`,
                      text: file.name
                    })
                  })
                  .catch((error) => {
                    if (!axios.isCancel(error)) {
                      showToast({
                        type: 'error',
                        title: t`Austauschen fehlgeschlagen`,
                        text: error?.response?.data?.message ?? file.name
                      })
                    }
                  })
                  .finally(() => {
                    refresh(
                      ({ path }) =>
                        path.includes('specificfiles') ||
                        path.includes('regulationfiles') ||
                        path.includes('complaintfiles') ||
                        path.includes('complaints') ||
                        path.includes('internalfiles')
                    )
                  })

                return {
                  promise,
                  cancel: () => cancelTokenSource.cancel('User abort')
                }
              },
              description: file.name,
              resolve: onDone,
              reject: onDone
            })
          )
        }
      }
    }
    window.addEventListener(
      'focus',
      () => {
        document.body.removeChild(input)
      },
      { once: true }
    )
    input.dispatchEvent(new MouseEvent('click'))
  }, [data.fileId, data.type, locale, refresh, settings?.uploads.regulationFiles.types])
  return [replaceFile, isActionAllowed]
}

export type DeleteFileLocation = {
  type: 'regulationfile' | 'specificfile'
  fileId: number
}
export const useCanDeleteFile = (request: DeleteFileLocation): boolean => {
  const isActionAllowed = usePermission(
    request.type === 'specificfile' ? 'Alle_Lieferanten_verwalten' : 'Alle_Dateien_verwalten'
  )
  return isActionAllowed
}

export const useCanDeleteComplaintFile = (): boolean => {
  const isActionAllowed = usePermission('Alle_Reklamationen_verwalten')
  return isActionAllowed
}

export const useDeleteFile = (request: DeleteFileLocation): [deleteFile: () => void, isActionAllowed: boolean] => {
  const refresh = useRefreshSWR()
  const isActionAllowed = useCanReplaceFile(request)
  const confirm = useConfirm()
  const deleteFileRequest = useTrackedAxiosRequest<[file: DeleteFileLocation], { data: unknown }>(
    () => ({
      createRequestData: (data) => {
        const url = data.type === 'regulationfile' ? `/regulationfiles/${data.fileId}` : `/specificfiles/${data.fileId}`

        return [url, undefined, { method: 'DELETE' }]
      },
      thenFn: () => {
        refresh(
          ({ path }) =>
            path.includes('specificfiles') ||
            path.includes('regulationfiles') ||
            path.includes('complaintfiles') ||
            path.includes('complaints') ||
            path.includes('internalfiles')
        )
      },
      messages: {
        success: {
          title: t`Löschen erfolgreich`
        },
        error: {
          title: t`Löschen fehlgeschlagen`
        }
      }
    }),
    [refresh]
  )
  const deleteFile = useCallback(
    () =>
      confirm({
        title: t`Datei löschen?`,
        text: t`Soll die Datei wirklich gelöscht werden?`,
        options: [
          { label: t`Abbrechen` },
          {
            type: 'danger',
            label: t`Löschen`,
            onClick: () => {
              deleteFileRequest.run(request)
            }
          }
        ]
      }),
    [confirm, deleteFileRequest, request]
  )
  return [deleteFile, isActionAllowed]
}

const deleteComplaintFile = async (complaint: number, complaintFile: number | undefined, locale: string) => {
  const url = `/${locale}/complaints/${complaint}/complaintfiles/${complaintFile}`
  await axios.delete(url)
}

export const useDeleteComplaintFile = (
  complaint: number,
  complaintFile: number | undefined
): [deleteFile: () => void, isActionAllowed: boolean] => {
  const refresh = useRefreshSWR()
  const isActionAllowed = useCanDeleteComplaintFile()
  const confirm = useConfirm()
  const locale = useCurrentLocale()
  const deleteFile = useCallback(
    () =>
      confirm({
        title: t`Datei löschen?`,
        text: t`Soll die Datei wirklich gelöscht werden?`,
        options: [
          { label: t`Abbrechen` },
          {
            type: 'danger',
            label: t`Löschen`,
            onClick: () => {
              deleteComplaintFile(complaint, complaintFile, locale).then(() =>
                refresh(({ path }) => path.includes('complaints') || path.includes('complaintfiles'))
              )
            }
          }
        ]
      }),
    [complaint, complaintFile, confirm, locale, refresh]
  )
  return [deleteFile, isActionAllowed]
}
