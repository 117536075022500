import { t } from '@lingui/macro'
import { toNumber } from 'lodash'
import { ColumnData, useColumnData } from '../../../../components/ListView/DataColumn'
import { SupplierDataMinimal, useSuppliers } from '../../../../data/remote/Supplier'
import { SetParamsAction } from '../../../../navigation/createRoute'

export const useSupplierData = ({
  setParams,
  generatePath,
  supplierId
}: {
  setParams: (
    action: SetParamsAction<'Certificates'> | SetParamsAction<'CertificatesBySupplier'>,
    clearOptionals?: boolean | undefined
  ) => void
  generatePath: (
    action: SetParamsAction<'Certificates'> | SetParamsAction<'CertificatesBySupplier'>,
    clearOptionals?: boolean | undefined
  ) => string
  supplierId: string | number | undefined
}): ColumnData<SupplierDataMinimal> =>
  useColumnData<SupplierDataMinimal>(
    () => ({
      useItems(search, filter) {
        return useSuppliers({
          search,
          filter
        })
      },
      isActiveItem(item) {
        return item.id === toNumber(supplierId)
      },
      shouldHaveActiveItem: !!supplierId,
      onActiveItemMiss() {
        setParams({
          supplierId: undefined,
          orderId: undefined,
          productId: undefined,
          certificateCode: undefined
        })
      },
      filterOptions: {
        ['']: t`Alle Lieferanten`,
        certificate_pending: t`Mit ungeprüften Zeugnissen`,
        certificate_mandatory: t`Mit offenen Codes`,
        certificate_open: t`Mit offenem Status`
      },
      renderItem: (supplier) => ({
        id: supplier.id,
        title: supplier.company,
        url: generatePath({ supplierId: supplier.id.toString() }, true),
        gray: supplier.status.certificates.approval === 'closed',
        approved:
          supplier.status.certificates.approval === 'approved' || supplier.status.certificates.approval === 'closed',
        denied: supplier.status.certificates.approval === 'denied',
        pending: supplier.status.certificates.pending,
        mandatory: supplier.status.certificates.mandatory
      })
    }),
    [generatePath, setParams, supplierId]
  )
