import { t } from '@lingui/macro'
import { SearchColumnProps } from '.'
import { ColumnData, useColumnData } from '../../../../components/ListView/DataColumn'
import { SupplierDataMinimal, useSuppliers } from '../../../../data/remote/Supplier'
import defaultColumnData from './defaultColumnData'
import { getParentSelection } from './utils'

export const useSupplierData = ({
  setSelection,
  setColumnSelection,
  selection,
  entrypoint,
  setEntrypoint
}: SearchColumnProps): ColumnData<SupplierDataMinimal> =>
  useColumnData<SupplierDataMinimal>(
    ({ setFilter }) => {
      return {
        useItems(search, filter) {
          const { orders: orderId } = getParentSelection(entrypoint, selection, 'suppliers')
          return useSuppliers(
            {
              search,
              filter,
              orderId
            },
            !!orderId || entrypoint === 'suppliers'
          )
        },
        filterOptions: {
          ['']: t`Alle Lieferanten`,
          certificate_pending: t`Mit ungeprüften Zeugnissen`,
          certificate_mandatory: t`Mit offenen Codes`,
          certificate_open: t`Mit offenem Status`
        },
        renderItem: (supplier) => ({
          id: supplier.id,
          title: supplier.company,
          onClick: () => {
            setColumnSelection(supplier.id, false)
          },
          onDoubleClick: () => {
            setColumnSelection(supplier.id, true, setFilter, supplier.company)
          },
          gray: supplier.status.certificates.approval === 'closed',
          approved:
            supplier.status.certificates.approval === 'approved' || supplier.status.certificates.approval === 'closed',
          denied: supplier.status.certificates.approval === 'denied',
          pending: supplier.status.certificates.pending,
          mandatory: supplier.status.certificates.mandatory
        }),
        ...defaultColumnData('suppliers', {
          entrypoint,
          setEntrypoint,
          selection,
          setSelection
        })
      }
    },
    [entrypoint, selection, setColumnSelection, setEntrypoint, setSelection]
  )
