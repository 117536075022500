export type BasicRouteInfo = {
  navigatorType: 'main' | 'login'
}

export type RouteParams<FixedParamName extends string = '', OptionalParamName extends string = ''> = {
  fixed: FixedParamName[]
  optional: OptionalParamName[]
  navigationParameterString: string
}

const mkRoute = <FixedParamName extends string = '', OptionalParamName extends string = ''>(
  fixed: FixedParamName[],
  optional: OptionalParamName[]
): RouteParams<FixedParamName, OptionalParamName> => ({
  fixed,
  optional,
  navigationParameterString: fixed
    .map((x) => `/:${x}`)
    .concat(optional.map((x) => `/:${x}?`))
    .reduce((a, b) => a + b, '')
})

const routeParams = {
  // Login
  Login: undefined,
  ForgotPassword: mkRoute([], ['userId', 'confirmationHash']),
  Activate: undefined,
  // Portal
  PageContent: undefined,
  Certificates: mkRoute([], ['orderId', 'productId', 'certificateCode']),
  CertificatesBySupplier: mkRoute([], ['supplierId', 'orderId', 'productId', 'certificateCode']),
  Complaints: mkRoute([], ['orderId', 'productId', 'complaintId']),
  SteelworkManagement: mkRoute(
    [],
    ['supplierId', 'steelHeatingId', 'steelCertificateId', 'steelworksId', 'dimensionsId']
  ),
  SteelworkProtocols: mkRoute(
    [],
    ['supplierId', 'steelHeatingId', 'steelCertificateId', 'steelworksId', 'dimensionsId']
  ),
  Search: undefined,
  FileManagement: mkRoute([], ['categoryId', 'folderId']),
  FileCheck: mkRoute([], ['supplierId', 'regulationFileId']),
  Files: mkRoute([], ['categoryId', 'folderId']),
  Suppliers: mkRoute([], ['supplierId']),
  SupplierRating: mkRoute([], ['supplierId']),
  EditProfile: undefined,
  Help: undefined,
  Information: undefined,
  Logout: undefined,
  ConfirmEmail: mkRoute(['userId', 'confirmationHash'], [])
} as const

export type RouteName = keyof typeof routeParams

export default routeParams
