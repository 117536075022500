import { toast as _toast, ToastContent, ToastOptions } from 'react-toastify'

export type ToastProps = ToastOptions & {
  title?: string
  text?: string
  content?: ToastContent
}

export const showToast = ({ title, text, content, type, ...props }: ToastProps): void => {
  const parse = (text: string) => {
    const parser = new DOMParser()
    const doc = parser.parseFromString(text, 'text/html')
    return doc.documentElement.innerHTML
  }
  const toastContent = (
    <>
      {title && <div className="Toastify__toast-title">{title}</div>}
      {text && (
        <div className="Toastify__toast-text">
          <span dangerouslySetInnerHTML={{ __html: parse(text) }} />
        </div>
      )}
      {content}
    </>
  )

  const autoClose = type === 'success' ? 2000 : 5000

  _toast(toastContent, {
    autoClose,
    hideProgressBar: false,
    type,
    ...props
  })
}
