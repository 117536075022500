import React, { useState } from 'react'
import { t } from '@lingui/macro'
import { truncate } from 'lodash'
// eslint-disable-next-line import/no-unresolved
import fileIcon from 'jsx:./../assets/icons/file.svg'
import Modal, { ModalRef } from './Overlays/Modal'
import { Icon } from './Icon'
import { Button, Form } from 'react-bootstrap'

export type FileUploadModalProps = {
  onUpload: (files: FileList) => void
  title: string
  acceptedFileTypes?: string
  maxFileSize?: number
  onClose?: () => void
}

export const FileUploadModal = React.forwardRef<ModalRef, FileUploadModalProps>(function FileUploadModal(
  { onUpload, title, acceptedFileTypes, maxFileSize, onClose },
  ref
) {
  const [dragging, setDragging] = useState(false)
  const [fileData, setFileData] = useState<FileList | null>(null)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_isDragAndDrop, setIsDragAndDrop] = useState(false)

  const handleUpload = () => {
    if (fileData) {
      onUpload(fileData)
      setFileData(null)
      onClose?.()
    }
  }

  const handleClose = () => {
    setFileData(null)
    onClose?.()
  }

  const readableFileSize = maxFileSize ? `${maxFileSize / 1024 / 1024} MB` : ''

  return (
    <Modal ref={ref} centered onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="w-75 mx-auto">
          <Form>
            <Form.Group>
              <div className="text-center">
                <div className="mb-3">
                  <div
                    className={`uploadheating mt-1 ${dragging ? 'dragging' : ''}`}
                    onDrop={(e) => {
                      e.preventDefault()
                      setFileData(e.dataTransfer.files)
                      setDragging(false)
                    }}
                    onDropCapture={(e) => {
                      e.preventDefault()
                      setFileData(e.dataTransfer.files)
                      setDragging(false)
                    }}
                    onDragOver={(e) => {
                      e.preventDefault()
                      setDragging(true)
                      setIsDragAndDrop(true)
                    }}
                    onDragLeave={(e) => {
                      if (e.currentTarget.contains(e.relatedTarget as Node)) {
                        setDragging(false)
                        setIsDragAndDrop(false)
                      }
                    }}
                  >
                    <Form.Label htmlFor="file" className="w-100 text-center my-1">
                      <div className="d-flex justify-content-center">
                        <div className="d-flex flex-row justify-content-center align-items-center">
                          <Icon svg={fileIcon} className="mr-2 fileIcon" />
                          <span>
                            {truncate(fileData?.[0]?.name, { length: 30 }) || t`Datei auswählen oder hier ablegen`}
                          </span>
                        </div>
                      </div>
                    </Form.Label>
                    <Form.Control
                      as="input"
                      type="file"
                      accept={acceptedFileTypes}
                      onChange={(e) => {
                        const target = e.target as HTMLInputElement
                        setFileData(target.files)
                        if (target.files) {
                          setIsDragAndDrop(true)
                        }
                      }}
                      onClick={() => {
                        if (fileData) {
                          setIsDragAndDrop(true)
                        }
                      }}
                      className="w-100 hidden-input"
                      multiple
                    />
                  </div>
                  <p className="mt-2">
                    {t`Erlaubte Dateitypen:`} {acceptedFileTypes}
                  </p>
                  <p>
                    {t`Maximale Dateigröße:`} {readableFileSize}
                  </p>
                </div>

                <Button
                  variant="primary"
                  onClick={handleUpload}
                  disabled={!fileData}
                  size="lg"
                  className="w-100 d-flex justify-content-center align-items-center"
                >
                  {t`Datei hochladen`}
                </Button>
              </div>
            </Form.Group>
          </Form>
        </div>
      </Modal.Body>
    </Modal>
  )
})
