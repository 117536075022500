import React from 'react'
import {
  Typo3ContentLayouts,
  Typo3ImageContent,
  Typo3TextContent,
  Typo3TextPicContent
} from '../../data/remote/PageContent'
import Gallery from './Gallery'

const TextPicModule: React.FC<TextPicModuleProps> = ({
  data: {
    content,
    appearance: { layout }
  }
}) => {
  const parseHTML = (html: string) => {
    const parser = new DOMParser()
    const doc = parser.parseFromString(html, 'text/html')
    return doc.documentElement.innerHTML
  }

  return (
    <>
      {content.header && layout !== Typo3ContentLayouts.Hint && <h2>{content.header}</h2>}
      {'gallery' in content && content.gallery.position.vertical !== 'below' && <Gallery data={content.gallery} />}
      {'bodytext' in content && content.bodytext && (
        <div className="content-container">
          {content.header && layout === Typo3ContentLayouts.Hint && <h3>{content.header}</h3>}
          {content.bodytext && <div dangerouslySetInnerHTML={{ __html: parseHTML(content.bodytext) }} />}
        </div>
      )}
      {'gallery' in content && content.gallery.position.vertical === 'below' && <Gallery data={content.gallery} />}
    </>
  )
}

export type TextPicModuleProps = {
  data: Typo3TextPicContent | Typo3ImageContent | Typo3TextContent
}

export default TextPicModule
