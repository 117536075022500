import { t } from '@lingui/macro'
import { SearchColumnProps } from '.'
import { ColumnData, useColumnData } from '../../../../components/ListView/DataColumn'
import { ProductDataMinimal, useProducts } from '../../../../data/remote/Product'
import defaultColumnData from './defaultColumnData'
import { getParentSelection } from './utils'

export const useProductsData = ({
  setSelection,
  selection,
  entrypoint,
  setEntrypoint,
  setColumnSelection
}: SearchColumnProps): ColumnData<ProductDataMinimal> =>
  useColumnData<ProductDataMinimal>(
    ({ setFilter }) => {
      return {
        useItems(search, filter) {
          const { orders: orderId, certificatecodes: certificateCodeId } = getParentSelection(
            entrypoint,
            selection,
            'products'
          )
          return useProducts(
            {
              search,
              filter,
              orderId,
              certificateCodeId
            },
            !!orderId || !!certificateCodeId || entrypoint === 'products'
          )
        },
        filterOptions: {
          ['']: t`Alle Artikel`,
          pending: t`Mit ungeprüften Zeugnissen`,
          denied: t`Mit abgelehnten Zeugnissen`,
          open: t`Mit offenem Status`
        },
        renderItem: (product) => ({
          id: product.id,
          title: product.sku,
          tooltip: product.localizedTitle,
          onClick: () => {
            setColumnSelection(product.id, false)
          },
          onDoubleClick: () => {
            setColumnSelection(product.id, true, setFilter, product.sku)
          },
          gray: product.status?.approval === 'closed',
          approved: product.status?.approval === 'approved' || product.status?.approval === 'closed',
          denied: product.status?.approval === 'denied',
          pending: product.status?.pending,
          mandatory: product.status?.mandatory
        }),
        ...defaultColumnData('products', {
          entrypoint,
          setEntrypoint,
          selection,
          setSelection
        })
      }
    },
    [entrypoint, selection, setColumnSelection, setEntrypoint, setSelection]
  )
