import { t } from '@lingui/macro'
import { ApiHookData, createApiDataHook, createApiItemHook } from '../../scripts/api'
import {
  TrackedAxiosRequest,
  useDebouncedTrackedAxiosRequest,
  useTrackedAxiosRequest
} from '../../scripts/useTrackedPromise'
import { prefixApiParams } from '../../scripts/utils'
import { useLocalizedRedirect } from './Language'
import { useRefreshSWR } from '../local/hooks/swrHooks'
import { GroupApprovalStatus } from './Status'
import { UserDataInput } from './User'
import { useEffect, useState } from 'react'
import { HeatingStatus } from '../../types/StatusTypes'

export type SupplierDataMinimal = {
  id: number
  company: string
  assignedLsw?: string[]
  status: {
    certificates: GroupApprovalStatus
    regulations: GroupApprovalStatus
    steelcertificates: HeatingStatus
  }
}

export type SupplierData = SupplierDataMinimal & {
  id?: number
  username: string
  language: number
  lastlogin: Date
  firstName: string
  lastName: string
  address: string
  zip: string
  city: string
  country: string
  telephone: string
  email: string
  isSupplier: boolean
  createdAt: Date
  supplierCode: string
  supplierNumber: string
  productGroups: number[]
  createdBy: number
  editedBy: number
  assignedRegulationFileIds: number[]
}

export type SupplierRating = {
  rating: {
    value: number
    class: string
    complaintCount: number
    orderPositionCount: number
    startDate: string
    endDate: string
  }
}

export const useSupplierData = createApiItemHook<SupplierData>('/suppliers')
export const useSupplier = (
  id?: number | undefined
): ApiHookData<SupplierData> & {
  updateData: (nextData: UserDataInput) => void
  updateDataLoading: boolean
} => {
  const supplierData = useSupplierData(id)
  const refresh = useRefreshSWR()
  const updateData = useTrackedAxiosRequest<[nextData: UserDataInput], { user: SupplierData }>(
    () => ({
      createRequestData: (newData) => {
        if (!id) {
          throw new Error('Unknown supplier')
        }
        return ['/suppliers/' + id, prefixApiParams(newData), { method: 'PATCH' }]
      },
      thenFn() {
        refresh(({ path }) => {
          return path.includes('/suppliers')
        })
      },
      messages: {
        success: {
          title: t`Speichern erfolgreich`
        },
        error: {
          title: t`Speichern fehlgeschlagen`
        }
      }
    }),
    [id, refresh]
  )

  return { ...supplierData, updateData: updateData.run, updateDataLoading: updateData.running }
}

export const useTestRedirect = () => {
  const redirect = useLocalizedRedirect()
  redirect('/suppliers/105')
}

export const useCreateSupplier = (): {
  run: (supplierData: UserDataInput, onSuccess?: (data: SupplierData) => unknown) => void
  running: boolean
} => {
  const refresh = useRefreshSWR()
  return useTrackedAxiosRequest<
    [supplierData: UserDataInput, onSuccess?: (data: SupplierData) => unknown],
    SupplierData
  >(
    () => ({
      createRequestData: (supplierData) => {
        return ['/suppliers', prefixApiParams(supplierData)]
      },
      thenFn: (response, inputData, onSuccess) => {
        refresh(({ path }) => {
          return path.includes('/suppliers')
        }).then(() => {
          onSuccess?.(response.data.data.id as unknown as SupplierData)
        })
      },
      messages: {
        success: {
          title: t`Lieferant erstellt`
        },
        error: {
          title: t`Lieferant konnte nicht erstellt werden`
        }
      }
    }),
    [refresh]
  )
}

export const useDeleteSupplier = (): TrackedAxiosRequest<[id: number, redirectUrl?: string]> => {
  const localizedRedirect = useLocalizedRedirect()
  const refresh = useRefreshSWR()
  return useTrackedAxiosRequest<[id: number, redirectUrl?: string], { data: SupplierData }>(
    () => ({
      createRequestData: (id) => {
        return ['/suppliers/' + id, undefined, { method: 'DELETE' }]
      },
      thenFn: (response, inputData, redirectUrl) => {
        refresh(({ path }) => {
          return path.includes('/suppliers')
        }).then(() => {
          if (redirectUrl) {
            localizedRedirect(redirectUrl)
          }
        })
      },
      messages: {
        success: {
          title: t`Löschen erfolgreich`
        },
        error: {
          title: t`Löschen fehlgeschlagen`
        }
      }
    }),
    [localizedRedirect, refresh]
  )
}

export type UseSuppliersParams = {
  search?: string | null
  filter?: string | null
  orderId?: number
}

export const useSuppliers = createApiDataHook<SupplierDataMinimal[], UseSuppliersParams>(({ orderId, ...args }) => [
  orderId ? `/orders/${encodeURIComponent(orderId)}/suppliers` : '/suppliers',
  args
])

export const useAssignedRegulationFileIds = (
  supplierId?: number
): TrackedAxiosRequest<[regulationFileIds: number[]], SupplierData> & {
  regulationFileIds: number[]
} => {
  const { data, reload } = useSupplier(supplierId)
  const [regulationFileIds, _setRegulationFileIds] = useState(data?.assignedRegulationFileIds ?? [])
  useEffect(() => {
    _setRegulationFileIds(data?.assignedRegulationFileIds ?? [])
  }, [data?.assignedRegulationFileIds])
  const trackedRequest = useDebouncedTrackedAxiosRequest(
    useTrackedAxiosRequest<[regulationFileIds: number[]], SupplierData>(
      () => ({
        createRequestData(nextIds) {
          return [
            `/suppliers/${supplierId}`,
            prefixApiParams({ assignedRegulationFileIds: nextIds }),
            { method: 'PATCH' }
          ]
        },
        repetitionBehavior: 'cancel',
        thenFn(response) {
          _setRegulationFileIds(response.data.data.assignedRegulationFileIds)
          reload(response.data.data, false)
        }
      }),
      [reload, supplierId]
    ),
    _setRegulationFileIds
  )

  return {
    ...trackedRequest,
    regulationFileIds
  }
}

export const useAssignedProductGroupsForSupplier = (
  supplierId?: number
): TrackedAxiosRequest<[productGroups: number[]], SupplierData> & {
  productGroupIds: number[]
} => {
  const { data, reload } = useSupplier(supplierId)

  const [productGroupIds, _setProductGroups] = useState(data?.productGroups ?? [])
  useEffect(() => {
    _setProductGroups(data?.productGroups ?? [])
  }, [data?.productGroups])
  const trackedRequest = useDebouncedTrackedAxiosRequest(
    useTrackedAxiosRequest<[productGroups: number[]], SupplierData>(
      () => ({
        createRequestData(nextIds) {
          return [`/suppliers/` + supplierId, prefixApiParams({ productGroupIds: nextIds }), { method: 'PATCH' }]
        },
        repetitionBehavior: 'cancel',
        thenFn(response) {
          _setProductGroups(response.data.data.productGroups)
          reload(response.data.data, false)
        }
      }),
      [supplierId, reload]
    ),
    _setProductGroups
  )

  return {
    ...trackedRequest,
    productGroupIds
  }
}

export const useAssignedProductGroupsForUser = (
  productGroups?: number[]
): TrackedAxiosRequest<[productGroups: number[]], SupplierData> & {
  productGroupIds: number[]
} => {
  const [productGroupIds, _setProductGroups] = useState(productGroups ?? [])
  useEffect(() => {
    _setProductGroups(productGroups ?? [])
  }, [productGroups])
  const trackedRequest = useDebouncedTrackedAxiosRequest(
    useTrackedAxiosRequest<[productGroups: number[]], SupplierData>(
      () => ({
        createRequestData(nextIds) {
          return [`/user`, prefixApiParams({ productGroupIds: nextIds }), { method: 'PATCH' }]
        },
        repetitionBehavior: 'cancel',
        thenFn(response) {
          _setProductGroups(response.data.data.productGroups)
        }
      }),
      []
    ),
    _setProductGroups
  )

  return {
    ...trackedRequest,
    productGroupIds
  }
}

export const useSupplierRating = createApiDataHook<
  SupplierRating,
  { supplierId: number; startDate?: string; endDate?: string }
>(({ supplierId, startDate, endDate }) => [`/suppliers/${supplierId}/rating`, { startDate, endDate }])
