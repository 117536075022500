import { SWRResponse } from 'swr'
import { useSWR } from '../local/hooks/swrHooks'
import { useLanguages } from './Language'
import { useRoutes } from './RouteData'
import { useAuthentication } from './User'

export type SettingsData = {
  appVersion: string
  serviceAvailable: boolean
  certificateReminder: {
    bcc: string
  }
  complaintEmail: {
    bccInternal: string
    bccSupplier: string
    cc: string
  }
  steelworks: {
    email: string
  }
  registration: {
    email: string
  }
  pages: {
    certificates: number
    certificatesBySupplier: number
    search: number
    fileManagement: number
    fileCheck: number
    files: number
    suppliers: number
    help: number
    information: number

    profile: number
    logout: number

    login: number
    forgotPassword: number
    activate: number
  }
  /**
   * Limitations for uploads by upload-type
   */
  uploads: {
    [key in
      | 'certificates'
      | 'complaintFiles'
      | 'confirmationFiles'
      | 'internalFiles'
      | 'regulationFiles'
      | 'steelCertificates']: {
      maxSize: number
      /** Array of Mime-Types */
      types: string[]
      typeExtensions: string[]
    }
  }
}

export const useSettings = (): {
  loading: boolean
  settings: SettingsData | undefined
  serviceAvailable: boolean
  appVersion: string | undefined
  reload: SWRResponse<SettingsData, Error>['mutate']
} => {
  const { data, error, mutate: reload } = useSWR<SettingsData>('/settings')

  const loading = !data && !error
  const serviceAvailable = !loading && !!data?.serviceAvailable
  const appVersion = data?.appVersion

  return {
    loading,
    settings: data,
    serviceAvailable,
    appVersion,
    reload
  }
}

export const useServiceStatus = (): { loading: boolean; available?: boolean } => {
  const { loading: languageLoading } = useLanguages()
  const { loading: settingsLoading, serviceAvailable } = useSettings()
  const { loading: routesLoading } = useRoutes()
  const { loggedIn, loggingIn: userLoading } = useAuthentication()

  const loading = languageLoading || settingsLoading || routesLoading || userLoading || loggedIn
  const available = loading ? undefined : serviceAvailable

  return {
    loading,
    available
  }
}
