import { plural, t } from '@lingui/macro'
import { apiParamsPrefix } from './config'

const isObject = (val: unknown): val is Record<string, unknown> =>
  typeof val === 'object' && !Array.isArray(val) && val !== null && !(val instanceof File)

/**
 * flattens object and prefixes all keys (default: TYPO3 plugin prefix)
 * @param obj
 * @returns updated object
 */
export const prefixApiParams = <T extends { readonly [key: string]: unknown }>(obj: T): Record<string, unknown> => {
  const addDelimiter = (a: string | undefined, b: string): string => (a ? `${a}[${b}]` : b)

  const prefixedParams: Record<string, unknown> = {}
  const paths = (obj: Record<string, unknown> = {}, head = apiParamsPrefix): ([string, unknown] | string)[] => {
    return Object.entries(obj).reduce((product, [key, value]) => {
      const fullPath = addDelimiter(head, key)
      if (isObject(value)) {
        return product.concat(paths(value, fullPath))
      } else if (Array.isArray(value) && value.length === 0) {
        prefixedParams[fullPath] = '[]'
        return product.concat(fullPath)
      } else {
        prefixedParams[fullPath] = value
        return product.concat(fullPath)
      }
    }, [] as ([string, unknown] | string)[])
  }

  paths(obj)

  return prefixedParams
}

export const toNumber = (input: unknown): number | undefined => {
  if (typeof input === 'string' || typeof input === 'number') {
    return !isNaN(+input) ? +input : undefined
  }
  return undefined
}

/**
 * @param interval number of months
 * @returns "Monthly", "Every # months", "Annually", "Every # years"
 */
export const intervalString = (interval: number) => {
  if (interval === 999) {
    return t`Einmalig`
  }

  const isAnnually = interval > 6 && interval % 6 === 0

  if (isAnnually) {
    return plural(interval / 12, {
      one: 'Jährlich',
      other: 'Alle # Jahre'
    })
  }

  return plural(interval, {
    one: 'Monatlich',
    other: 'Alle # Monate'
  })
}
