import { t } from '@lingui/macro'
import dayjs from 'dayjs'
import { useMemo } from 'react'
import { createApiDataHook, createApiItemHook } from '../../scripts/api'
import { TrackedAxiosRequest, useTrackedAxiosRequest } from '../../scripts/useTrackedPromise'
import { prefixApiParams } from '../../scripts/utils'
import { useRefreshSWR } from '../local/hooks/swrHooks'
import { ApprovalStatus } from './Status'
import { usePermission } from './User'
import { FileData } from '../../types/FileDataTypes'

export type CertificateDataMinimal = {
  id: number
  file: FileData
  status: {
    approval: ApprovalStatus
    checkedBy: string
    checkedDate: string
    locked: boolean
  }
  tstamp: string
  /** Number of comments attached to this certificate */
  comments: number
}

export type CertificateData = CertificateDataMinimal

export const useCertificate = createApiItemHook<CertificateData>('/certificates')

export type UseCertificatesParams =
  | {
      codeId?: string | number
    }
  | {
      codeId?: undefined
      code?: string
    }

export const useCertificates = createApiDataHook<CertificateDataMinimal[], UseCertificatesParams>(
  ({ codeId, ...params }) => [codeId ? `/certificatecodes/${codeId}/certificates` : '/certificates', params]
)

export const getStatusText = (status: CertificateDataMinimal['status']): string => {
  switch (status.approval) {
    case 'approved':
      return t`Bestätigt durch ${status.checkedBy} am ${dayjs(status.checkedDate).format('L')}`
    case 'denied':
      return t`Abgelehnt durch ${status.checkedBy} am ${dayjs(status.checkedDate).format('L')}`
    default:
      return t`Ausstehend`
  }
}

export const useCanApproveCertificates = (): boolean => usePermission('Zeugnisse_pruefen')

export const useUpdateCertificateApproval = (
  certificateId: number | number[]
): TrackedAxiosRequest<[{ approval: 'pending' | 'approved' | 'denied' }]> & {
  approve: () => void
  deny: () => void
} => {
  const refresh = useRefreshSWR()
  const actionAllowed = useCanApproveCertificates()
  const result = useTrackedAxiosRequest<
    [
      {
        approval: 'pending' | 'approved' | 'denied'
      }
    ],
    unknown
  >(
    () => ({
      createRequestData: (args) => {
        return [
          typeof certificateId === 'number' ? `/certificates/${certificateId}` : '/certificates',
          prefixApiParams<{ certificates?: number[]; approval: 'pending' | 'approved' | 'denied' }>(
            typeof certificateId === 'number'
              ? args
              : {
                  certificates: certificateId,
                  ...args
                }
          ),
          { method: 'PATCH' }
        ]
      },
      thenFn: () => {
        refresh(({ path }) => {
          return path.includes('/certificates')
        })
      },
      messages: {
        error: {
          title:
            typeof certificateId === 'number'
              ? t`Das Zertifikat konnte nicht angepasst werden.`
              : t`Die Zertifikate konnten nicht angepasst werden.`
        },
        success:
          typeof certificateId === 'number'
            ? undefined
            : {
                title: t`Alle offenen Zeugnisse wurden freigegeben.`
              }
      },
      actionAllowed
    }),
    [actionAllowed, certificateId, refresh]
  )

  return useMemo(
    () => ({
      ...result,
      approve: () => result.run({ approval: 'approved' }),
      deny: () => result.run({ approval: 'denied' })
    }),
    [result]
  )
}

export const useDeleteCertificate = () => {
  const refresh = useRefreshSWR()

  return useTrackedAxiosRequest<[data: { codeId: number; certificateId: number }, onSuccess?: () => unknown], void>(
    () => ({
      createRequestData: ({ codeId, certificateId }) => {
        return [`/certificatecodes/${codeId}/certificates/${certificateId}`, undefined, { method: 'DELETE' }]
      },
      thenFn: (response, inputData, onSuccess) => {
        refresh(({ path }) => {
          return path.includes('/certificatecodes')
        }).then(() => {
          onSuccess?.()
        })
      },
      messages: {
        success: {
          title: t`Zertifikat erfolgreich gelöscht`
        },
        error: {
          title: t`Zertifikat konnte nicht gelöscht werden`
        }
      }
    }),
    [refresh]
  )
}
